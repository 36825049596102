import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import garmentServices from '../assets/img/garmentservices_carousel.png'
import jewelryServices from '../assets/img/jewelryservices_carousel.png'
import factories from '../assets/img/factories_carousel.png'
import brands from '../assets/img/brands_carousel.png'
import recruiters from '../assets/img/recruiters_carousel.png'
import graphicsAndDesign from '../assets/img/graphicsanddesign_carousel.png'
import development from '../assets/img/development_carousel.png'
import digitalMarketing from '../assets/img/digitalmarketing_carousel.png'
import sales from '../assets/img/sales_carousel.png'
import fashionProfessionals from '../assets/img/fashionprofessionals_carousel.png'
import student from '../assets/img/student_carousel.png'

export const Categories = () => {
  const responsive = {
    superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1024 },
    items: 4,
    slidesToSlide: 2,
    },
    desktop: {
    breakpoint: { max: 1024, min: 800 },
    items: 2,
    },
    tablet: {
    breakpoint: { max: 800, min: 464 },
    items: 2,
    },
    mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    },
  };
  
  return (
    <section className="categories" id="categories">
      <div className="categories-title">
        <h1>Platform Services</h1>
      </div>
      <div className="categories-container">
        <Carousel 
          responsive={responsive}
          itemClass="carousel-item-padding-40-px"
          infinite={true}
          showDots={true}
          dotListClass="custom-dot-list-style"
          containerClass="carousel-container"
          // autoPlay={true}
          // autoPlaySpeed={3500}
          shouldResetAutoplay={false}
          // removeArrowOnDeviceType={["tablet", "mobile"]}
        >
          <div className="carousel-card">
            <img className="carousel-image" src={garmentServices} alt="Garment Services" />
          </div>
          <div className="carousel-card">
            <img className="carousel-image" src={recruiters} alt="Recruiters" />
          </div>
          <div className="carousel-card">
            <img className="carousel-image" src={brands} alt="Brands" />
          </div>
          <div className="carousel-card">
            <img className="carousel-image" src={fashionProfessionals} alt="Fashion Professionals" />
          </div>
          <div className="carousel-card">
            <img className="carousel-image" src={development} alt="Development" />
          </div>
          <div className="carousel-card">
            <img className="carousel-image" src={sales} alt="Sales" />
          </div>
          <div className="carousel-card">
            <img className="carousel-image" src={graphicsAndDesign} alt="Graphics and Design" />
          </div>
          <div className="carousel-card">
            <img className="carousel-image" src={jewelryServices} alt="Jewelry Services" />
          </div>
          <div className="carousel-card">
            <img className="carousel-image" src={factories} alt="Factories" />
          </div>
          <div className="carousel-card">
            <img className="carousel-image" src={digitalMarketing} alt="Digital Marketing" />
          </div>
          <div className="carousel-card">
            <img className="carousel-image" src={student} alt="Student" />
          </div>
        </Carousel>
      </div>
    </section>
  )
}
