export const TermsAndConditions = () => {
    return(
        <div className="regulation-container">
            <div className="regulation-text-container">
                <h1>Terms and Conditions</h1>
                <br/>
                <p>
                THE AGREEMENT: The use of this website and services on this website provided by 99 YARDS  (99Yards.app referred to as "Website, company or platform") are subject to the following Terms & Conditions, all parts and sub-parts of which are specifically incorporated by reference here. This agreement shall govern the use of all pages on this website (hereinafter collectively referred to as "Website") and any services provided by or on this website ("Services").
                                        DEFINITIONS
                                        "Agreement" denotes to this Terms and Conditions and the Privacy Policy and other documents provided to you by the website.
                                        "We," "us," and "our" are references to 99YARDS.APP.
                                        "User," "You," and "your" denotes the person who is accessing the website for taking or availing of any service from us. User shall include the company, partnership, sole trader, person, body corporate, or association taking services of this website.
                                        "Artist" is a person who sells his/her services through the platform.
                                        A "Buyer" is a person who buys the service through the platform.
                                        " Website" shall mean and include 99Yards.app and any successor Website of the Company or any of its affiliates.
                                        Parties: Collectively, the parties to this Agreement (We and You) will be referred to as Parties.
                                        ASSENT & ACCEPTANCE
                                        PLEASE READ THESE TERMS OF USE, OUR PRIVACY POLICY, AND ALL APPLICABLE SUPPLEMENTAL TERMS (COLLECTIVELY, THE "TERMS") CAREFULLY, AS THEY CONTAIN TERMS AND CONDITIONS THAT IMPACT YOUR RIGHTS, OBLIGATIONS, AND REMEDIES IN CONNECTION WITH YOUR USE OF THE SERVICES AND CONTENT. FOR EXAMPLE, THE TERMS INCLUDE:

                                        YOUR OBLIGATION TO COMPLY WITH ALL APPLICABLE LAWS AND REGULATIONS.
                                        LIMITATIONS OF OUR LIABILITY TO YOU; AND
                                        A REQUIREMENT THAT YOU PURSUE CLAIMS OR SEEK RELIEF AGAINST US (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS, RATHER THAN AS A PARTICIPANT IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING.
                                        YOUR ACCESS TO AND USE OF THE SERVICES IS CONDITIONED ON YOUR ACCEPTANCE OF AND COMPLIANCE WITH ALL APPLICABLE TERMS. If you do not agree to these Terms or our Privacy Policy, then please cease using the Services immediately. We reserve the right to change these Terms at any time (see "Changes to these Terms" below.) By accessing, browsing, and/or using the Services after updates to these Terms have been posted, you agree to be bound by the updated Terms. THESE TERMS AND OUR PRIVACY POLICY CONSTITUTE A BINDING AGREEMENT BETWEEN YOU AND 99 Yards.
                                        Consequences of Non-Compliance
                                        Your failure to comply with the Terms may result in the suspension or termination of your account and/or access to the Services and may subject you to civil and criminal penalties.
                                        ELIGIBILITY 
                                        To access our Services through our website, you must be a legal entity or an individual of eighteen (18) years of age or older who can form legally binding contracts. To become a Registered User, you must accept all the terms and conditions in, incorporated by reference in, and linked to, these Terms and conditions. By becoming a Registered User, you agree to (1) Abide by the Terms and conditions and the processes, procedures, and guidelines described throughout the website; (2) Be financially responsible for your use of the website and the purchase or delivery of services; and (3) Perform your obligations as specified by any Job Agreement that you accept, unless such obligations are prohibited by law or by the Terms and conditions. 99 YARDS reserves the right, in its sole discretion, to refuse, suspend, or terminate Services to anyone.
                                        REGISTRATION
                                        To become a Registered User and to access Services, you must register for an Account. You agree to provide true, accurate, and complete information as prompted by the registration form and all forms you access on the website and to update this information to maintain its truthfulness, accuracy, and completeness.
                                        ACCOUNTS AND PROFILES
                                        General. Once you have registered with the website as a Registered User, the website will create your Account with Company and associate it with an account number. You may create a profile under your Account, Username, and Password. During registration, you will be asked to choose a username and password for the Account. As a Registered User, you agree, and you are entirely responsible for safeguarding and maintaining the confidentiality of the username and password you use to access this website. You authorize Company to assume that any person using the website with your username and password is you or is authorized to act for you. You agree to notify us immediately if you suspect any unauthorized use of the account.
                                        TAXES
                                        You are responsible for paying any taxes, including any services or value-added taxes, which may be applicable depending on the jurisdiction of the services provided.
                                        Depending on your residency or location, you may be subject to certain ad valorem or other taxes (for example, GST) on certain fees that we charge. These taxes will be added to fees billed to you, if applicable.
                                        Artists are 100% responsible for recording and paying their own tax in their countries based on the income they earn from 99 YARDS .
                                        You acknowledge that you must comply with your obligations under income tax provisions in your jurisdiction.
                                        BUYER AND ARTIST
                                        Job Agreement: The engagement, contracting, and management of a Job are between a Buyer and an Artist. Upon acceptance of a Quote, the Buyer agrees to purchase, and the Artist agrees to deliver, the services and related deliverables in accordance with the following agreements: (a) the Job Agreement between Buyer and Artist including the Quote, Job Description, and other terms and conditions as communicated between Buyer and Artist on the website or otherwise, (b) these Terms of Service, and (c) any other content uploaded to the Website by Company (collectively, the "Job Agreement"). You agree not to enter any contractual provisions in conflict with these Terms of Service. Any provision of a Job Agreement in conflict with these Terms of Service is void. Buyer is responsible for managing, inspecting, accepting, and paying for satisfactory services and deliverables in accordance with the Job Agreement in a timely manner. Artist is responsible for the performance and quality of the services in accordance with the Job Agreement in a timely manner. Buyer and Artist each covenant and agrees to act with good faith and fair dealing in performance of the Job Agreement.
                                        Independence: Buyer and Artist each acknowledge and agree that their relationship is that of independent contractors. The Artist shall perform services as an independent contractor, and nothing in these Terms of Service shall be deemed to create a partnership, joint venture, agency, or Buyer-employee relationship between Artist and Buyer or between Company and any Buyer or Artist.
                                        SERVICE FEES
                                        The company deducts one or more of the following fees, as applicable, from users using the Company Billing and Payment Services:

                                        We charge 12% booking fee
                                        We charge 9% when they take out funds
                                        We reserve the right to change any fees associated with the Company at any time, at the sole discretion of the Company. No refunds of fees already paid will be given. If we exercise our right to cancel a membership as provided under these Terms of Service at any time, we will not refund the membership fee already paid.
                                        TERM; TERMINATION AND SUSPENSION
                                        These Terms and conditions shall become effective as your contractual agreement upon your use of the website and shall continue until your Account is terminated by you or Company as provided for under the terms of this section.

                                        Unless otherwise agreed to in writing between the parties, either party may terminate the contractual agreement represented by these Terms and conditions at any time upon notice to the other party. In such event, your Account is automatically terminated, and (1) Company shall continue to perform those services necessary to complete any open transaction between you and another Registered User; and (2) You shall continue to be obligated to pay any amounts accrued but unpaid as of the date of termination to Company for any service and to any Artist for any services.
                                        Any termination of an Account will automatically lead to the termination of all related profiles.
                                        Without limiting our other remedies, we may issue a warning, or temporarily suspend, indefinitely suspend or terminate your Account or a Job, and refuse to provide any or all services to you if: (1) you breach the letter or spirit of any terms and conditions of these Terms and conditions or the linked policies and information incorporated herein by reference, including our written policies and procedures posted on the website; (2) we are unable to verify or authenticate any information you provide to us; or (3) we believe in our sole discretion that your actions may cause legal liability for you, our Registered Users or for Company or are contrary to the interests of the website. Once indefinitely suspended or terminated, you must not continue to use the website under the same Account, a different Account, or register under a new Account.
                                        In addition, violations of these Terms and conditions may be fully prosecuted of the law and may result in additional penalties and sanctions.
                                        Without limiting our other remedies, to the extent you engage in actions or activities which circumvent the Company Billing and Payment Services or otherwise reduce fees owed Company under these Terms and conditions, you must pay Company for all fees owed to Company and reimburse Company for all losses and costs and reasonable expenses (including attorney fees) related to investigating such breach and collecting such fees.
                                        When your Account is terminated for any reason, you may no longer have access to data, messages, files, and other material you keep on the website. The material may be deleted along with all your previous posts and proposals.
                                        USER ACCOUNT
                                        By registering for an account to use the Site or Site Services (an "Account"), by using the Site or Site Services after the Effective Date if you had an Account on the Effective Date, or by clicking to accept the Terms of Service when prompted on the Site, you agree to abide by this Agreement and the other Terms and conditions.
                                        ACCOUNT TYPES
                                        As described in this Section, there are several different Account types. Once you register for one Account type, you can add the other Account types under the same username and password. For example, if you already have an Artist Account, you can add Buyer Account (defined below) as a separate account type in settings without re-registering. You agree not to have or register for more than one Account without express written permission from us. We reserve the right to revoke the privileges of the Account or access to or use of the Site or Site Services, and those of all linked Accounts without warning if, in our sole discretion, false or misleading information has been provided in creating, marketing, or maintaining your Profile or Account.
                                        BUYER ACCOUNT
                                        You can register for an Account or add an Account type to use the Site and Site Services as a Buyer (a "Buyer Account"). 

                                        Buyers own full rights to their art after the order is completed.
                                        All communication must be kept on the 99 YARDS  website.
                                        99 YARDS the authority to delete misleading or false reviews left by buyers.
                                        ARTIST ACCOUNT

                                        Artists have the right to refuse an order without any reason.
                                        Artists can display work from previous orders on their portfolios even if the buyer does not consent.
                                        Artists can cancel and refund any order, even if halfway through the project.
                                        All communication must be kept on the 99 Yards website.
                                        Artists should not add other people's work to their portfolio and pretend it is their own; this will breach copyright laws.
                                        Artists should add correct and applicable style tags to their profile and upload work. Incorrect style tags can result in an account ban.
                                        How often do artists get paid? CLICK HERE
                                        Artists should not give false information about their location.
                                        PROHIBITED SITE USES
                                        You may not use, or encourage, promote, facilitate, or instruct, induce, or otherwise influence or cause others: (1) to use the Site or Site Services for any activities that violate any law, statute, ordinance or regulation; for any other illegal or fraudulent purpose or any purpose that is harmful to others, or (2) to transmit, store, display, distribute or otherwise make available content that is illegal, fraudulent or harmful to others. 99 YARDS has the authority to refund an order if any terms and conditions are broken by the artist or if the work that is sold is not original.
                                        EXAMPLES OF PROHIBITED USES OF THE SITE
                                        The following are examples of uses that are prohibited on the Site or when using the Site Services:
                                        Seeking, offering, promoting, supporting, or endorsing services, content, or activities that:

                                        All artwork displayed on the 99 YARDS  website and on artist profiles and explore pages are not to be reused or distributed anywhere else.
                                        Are defamatory, illegal, violent, profane, vulgar, threatening, unlawfully discriminatory, illegal, pornographic, obscene, or sexually explicit in nature.
                                        Would violate the intellectual property rights, such as and including copyrights, of another person, entity, service, product, or website.
                                        Would violate (a) the Company's Terms and Conditions, (b) the Terms and Conditions of another website or any similar contractual obligations, or (c) the academic policies of any educational institution.
                                        Regard the creation, purchase, and/or publication of a review for a third-party website.
                                        Regard the creation, publication, or distribution of "fake news," "hoax news," Deep Fake content, or similar content, which is, in Company's sole discretion, determined to be intended to mislead recipients for personal, financial, political, or other gain or advantage.
                                        Regard or promote in any way any escort services, prostitution, or sexual acts; or
                                        any conduct that is, in Company's determination, violent or harassing toward another person or endorses or encourages violence or harassment towards others, including based on the individual's or group's inclusion in a protected class as defined by applicable law.
                                        Fraudulent or misleading uses or content, including:

                                        Fraudulently billing or attempting to fraudulently bill any Client, including by (i) falsifying or manipulating or attempting to falsify or manipulate the hours, keystrokes, or mouse clicks recorded in the Company Platform, (ii) reporting, recording, or otherwise billing Clients for the time that was not actually worked, or (iii) on an hourly contract, reporting, recording, or otherwise billing hours worked by another person as hours worked by you in a way that is misleading or false.
                                        Misrepresenting your experience, skills, or information, including by representing another person's profile or parts of another person's profile, as your own.
                                        Using a profile photo that is not you misrepresents your identity or represents you as someone else or any other activity that misrepresents your identity.
                                        Impersonating any person or entity, including, but not limited to, Company representative, forum leader, or falsely stating or otherwise misrepresenting your affiliation with a person or entity.
                                        Falsely stating or implying a relationship with another User, including an Agency continuing to use a Artist's profile or information after the Artist no longer works with the agency.
                                        Falsely attributing statements to any Company representative, forum leader, guide, or host.
                                        Falsely stating or implying a relationship with Company or with another company with whom you do not have a relationship.
                                        Allowing another person to use your account, which is misleading to other Users; or
                                        Falsely stating that one Artist will perform the work on a job when another will in fact perform the work, including submitting a proposal on behalf of a Artist that is unable, unwilling, or unavailable to do the work.

                                        Expressing an unlawful preference in a job post or proposal or otherwise unlawfully discriminating on a protected basis.
                                        Inciting or encouraging violence.
                                        Posting identifying information concerning another person.
                                        Spamming other Users with proposals or invitations, including by making unsolicited contact of Users off the Company platform, or by posting the same job multiple times so that more than one version remains active at a given time.
                                        Making or demanding bribes or demanding other payments without the intention of or without providing services in exchange for the payment.
                                        Requesting or demanding free services, including requesting Artists to submit work as part of the proposal process for very little or no money or posting contests in which Artists submit work with no or very little pay, and only the winning submission is paid the full amount.
                                        Requesting a fee before allowing a User to submit a proposal.
                                        Attempting to or manipulating or misusing the feedback system, including by:

                                        withholding payment or Work Product or engaging in any other conduct for the purpose of obtaining positive feedback from another User.
                                        Attempting to coerce another User by threatening to give negative feedback.
                                        Expressing views unrelated to work, such as political, religious, or social commentary, in the feedback system.
                                        Providing anything of value to any person (including to a third-party who aids in obtaining feedback) or using any service of any type to obtain feedback; or
                                        offering services for the sole purpose of obtaining positive feedback of any kind.
                                        Duplicating or sharing accounts.

                                        Selling, trading, or giving an account to another person without Company's consent.
                                        Sharing or soliciting Means of Direct Contact with or from another User either directly through messages or by including Means of Direct Contact in a job post, profile, proposal, or other User Content prior to entering a Service Contract with such User, except as expressly allowed for Enterprise Clients.
                                        Directly or indirectly, advertising or promoting another website, product, or service or soliciting other Users for other websites, products, or services, including advertising on Company to recruit Artists and/or Clients to join an Agency or another website or company.
                                        Interfering or attempting to interfere with the proper operation of the Site or Site Services or any activities conducted on the Site, including by:

                                        bypassing any measures we may use to prevent or restrict access to the Site or any subparts of the Site, including, without limitation, features that prevent or restrict use or copying of any content or enforce limitations on use of the Site or the content therein.
                                        Attempting to interfere with or compromise, or interfering with or compromising, the system integrity or security of the servers running the Site or attempting to or deciphering any transmissions to or from the servers running the Site.
                                        Using any robot, spider, scraper, or other automated means to access the Site for any purpose without our express written permission.
                                        Collecting or harvesting any personally identifiable information, including Account names, from the Site.
                                        Attempting to or imposing an unreasonable or disproportionately large load (as determined in Company's sole discretion) on the Site's infrastructure.
                                        introducing any invalid data, virus, worm, or other harmful or malicious software code, agent, hidden procedure, routine, or mechanism through or to the Site that is designed to or known to cause to cease functioning, disrupt, disable, harm, or otherwise impair in any manner, including aesthetic disruptions or distortions, the operation of (or to allow you or any other person to access or damage or corrupt data, storage media, programs, equipment, or communications or otherwise interfere with operations of or on) the Site or any software, firmware, hardware, computer system, or network of Company or any third party;
                                        accessing or attempting to access the Site or Site Services by any means or technology other than the interface provided; or
                                        framing or linking to the Site or Site Services except as permitted in writing by Company.
                                        Conduct or actions that could jeopardize the integrity of or circumvent the Site, Site Services or Company's proprietary information, including:

                                        attempting to or reverse engineering, modifying, adapting, translating, preparing derivative works from, decompiling, or interfering with the operation of, or otherwise attempting to derive source code from, any part of the Site or Site Services unless expressly permitted by applicable law or Company; or
                                        accessing or using the Site or Site Services to build a similar service or application, identify or solicit Company Users, or publish any performance or any benchmark test or analysis relating to the Site.
                                        DISPUTES AND CANCELLATIONS
                                        99 YARDS has ultimate authority on how a dispute is resolved but we encouraged to work together to come to a solution. If you have a dispute with another Registered User from claims, demands cancellation refunds conflicts among themselves every kind and nature try to settle conflicts amongst themselves if fails you can contact customer support.
                                        USER OBLIGATIONS
                                        As a user of the Website or Services, you may be asked to register with us. When you do so, you will choose a user identifier, which may be your email address or another term, as well as a password. You may also provide personal information, including, but not limited to, your name. You are responsible for ensuring the accuracy of this information. This identifying information will enable you to use the Website and Services. You must not share such identifying information with any third party, and if you discover that your identifying information has been compromised, you agree to notify us immediately in writing. An email notification will suffice. You are responsible for maintaining the safety and security of your identifying information, as well as keeping us apprised of any changes to your identifying information. Providing false or inaccurate information or using the Website or Services to further fraud or unlawful activity is grounds for immediate termination of this agreement.
                                        ACCEPTABLE USE
                                        You agree not to use the Website or Services for any unlawful purpose or any purpose prohibited under this clause. You agree not to use the Website or Services in any way that could damage the Website, Services, or general business of 99 YARDS.app.

                                        You further agree not to use the Website or Services:
                                        To harass, abuse, or threaten others or otherwise violate any person's legal rights.
                                        To violate any of our intellectual property rights or any third party.
                                        To upload or otherwise disseminate any computer viruses or other software that may damage the property of another.
                                        To perpetrate any fraud.
                                        To engage in or create any unlawful gambling, sweepstakes, or pyramid scheme.
                                        To publish or distribute any obscene or defamatory material.
                                        To publish or distribute any material that incites violence, hate, or discrimination towards any group.
                                        To unlawfully gather information about others.
                                        INTELLECTUAL PROPERTY
                                        You agree that the Website and all Services provided by us are the property of 99 YARDS, including all copyrights, trademarks, trade secrets, patents, and other intellectual property ("Our IP"). You agree that we own all rights, title, and interest in and to the Our IP and that you will not use Our IP for any unlawful or infringing purpose. You agree not to reproduce or distribute Our IP in any way, including electronically or via registration of any new trademarks, trade names, service marks, or Uniform Resource Locators (URLs), without express written permission from us.

                                        To make the Website and Services available to you, you hereby grant us a royalty-free, non-exclusive, worldwide license to copy, display, use, broadcast, transmit and make derivative works of any content you publish, upload, or otherwise make available to the website ("Your Content"). We claim no further proprietary rights in your content.
                                        If you feel that any of your intellectual property rights have been infringed or otherwise violated by the posting of information or media by another of our users, please contact us and let us know.
                                        INDEMNIFICATION
                                        To the maximum extent permitted by law, you agree to indemnify, defend and hold harmless 9 PRO and its affiliates and/or related entities, whether direct or indirect, current, former or future, and its and their respective current, former, or future officers, directors, employees, agents, successors and assigns and related third parties (each an "Indemnified Party"), for any claims, causes of action, debts, damages, losses, costs, liabilities and expenses (including reasonable attorneys' fees) relating to or arising out of any third-party claim that (a) your use of or inability to use the Services, (b) any user postings made by you, (c) your violation of any terms of this agreement or your violation of any rights of a third-party, or (d) your violation of any applicable laws, rules or regulations, except to the extent caused by any unlawful or negligent act or omission by 99 YARDSA . 99 YARDS reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with 99 YARDS in asserting any available defenses. An Indemnified Party may participate in the defense by counsel of its own choosing, at its own cost and expense. You shall not settle any claim that adversely affects an Indemnified Party or imposes any obligation or liability on an Indemnified Party without the Indemnified Party's prior written consent.
                                        EXCLUSION OF LIABILITY
                                        You understand and agree that we (A) do not guarantee the accuracy, completeness, validity, or timeliness of information listed by us or any third parties, and (B) shall not be responsible for any materials posted by us or any third party. You shall use your judgment, caution, and common sense in evaluating any prospective methods or offers and any information provided by us or any third party.
                                        Further, we shall not be liable for direct, indirect, consequential, or any other form of loss or damage that may be suffered by a user using the 9pro.app Website including loss of data or information or any kind of financial or physical loss or damage.
                                        In no event shall 99 YARDS, nor its Owner, directors, employees, partners, agents, suppliers, or affiliates, be accountable for any indirect, incidental, special, eventful, or exemplary costs, including without limitation, loss of proceeds, figures, usage, goodwill, or other intangible losses, consequential from (i) your use or access of or failure to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content attained from the Service; and (iv) unlawful access, use or alteration of your transmissions or content, whether or not based on guarantee, agreement, domestic wrong (including carelessness) or any other lawful concept, whether or not we've been aware of the possibility of such damage, and even if a cure set forth herein is originated to have futile of its important purpose.
                                        MODIFICATION & VARIATION
                                        We may, from time to time and at any time without notice to you, modify this agreement. You agree that we have the right to modify this Agreement or revise anything contained herein. You further agree that all modifications to this agreement are in full force and effect immediately upon posting on the website and that modifications or variations will replace any prior version of this agreement unless prior versions are specifically referred to or incorporated into the latest modification or variation of this agreement.
                                        NO WARRANTIES
                                        You agree that your use of the Website and Services is at your sole and exclusive risk and that any Services provided by us are on an "As Is" basis. We hereby expressly disclaim any express or implied warranties of any kind, including but not limited to the implied warranty of fitness for a particular purpose and the implied warranty of merchantability. We make no warranties that the Website or Services will meet your needs or that the Website or Services will be uninterrupted, error-free, or secure. We also make no warranties as to the reliability or accuracy of any information on the website or obtained through the Services. You agree that any damage that may occur to you, through your computer system, or because of the loss of your data from your use of the Website or Services is your sole responsibility and that we are not liable for any such damage or loss.
                                        LIMITATION ON LIABILITY
                                        We are not liable for any damages that may occur to you because of your use of the Website or Services to the fullest extent permitted by law. This section applies to any claims by you, including, but not limited to, lost profits or revenues, consequential or punitive damages, negligence, strict liability, fraud, or torts of any kind.
                                        SEVERABILITY
                                        If any provision of these Terms and conditions is determined to be unlawful, void, or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms and conditions, such determination shall not affect the validity and enforceability of any other remaining provisions.
                                        CONTACTING US
                                        If you would like to contact us to understand more about terms or wish to contact us concerning any matter, you may do so via the contact us or email us at moirai9app@gmail.com. 
                                        99 YARDS 
                                        United States 
                                        This document was last updated on January 8, 2022
                </p>
            </div>

        </div>
    )
}