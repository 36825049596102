import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import {Banner} from "./components/Banner"
import { AboutUs } from './components/AboutUs';
import { HowItWorks } from './components/HowItWorks';
import { Categories } from './components/Categories';
import { Download } from './components/Download';
import { Footer } from './components/Footer';
import { Copyright } from './components/Copyright';
import { Partnerships } from './components/Partnerships';
import { Route, Routes } from 'react-router-dom';
import { Login } from './components/Login';
import { TermsAndConditions } from './components/TermsAndConditions';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { CodeOfConduct } from './components/CodeOfConduct';
import { WebsiteAccessibility } from './components/WebsiteAccessibility';
import { Admin } from './components/Admin';
// import { Sidebar } from './components/Sidebar';
import { PendingInvites } from './components/PendingInvites';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import VerifyEmail from './components/VerifyEmail';
// import { GetInvited } from './components/GetInvited';
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

function App() {
  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<>
            <NavBar />
            <Banner />
            <AboutUs />
            <HowItWorks />
            <Categories />
            <Partnerships />
            <Download />
            <Footer />
            <Copyright />
          </>} />
          {/* <Route path="/login" element={<>
            <NavBar />
            <Login />
          </>} /> */}
          <Route path="/verify-email" element={<VerifyEmail />} />

          <Route path="/login" element={<>
            <NavBar />
          <Login />
          </>} />
          <Route path="/register" element={<>
            <NavBar />
          <Register />
          </>} />
          <Route path="/dashboard1" element={<>
            <NavBar />
          <Dashboard />
          </>} />
          <Route path="/termsandconditions" element={<>
            <NavBar />
            <TermsAndConditions />
          </>} />
          <Route path="/privacypolicy" element={<>
            <NavBar />
            <PrivacyPolicy />
          </>} />
          <Route path="/codeofconduct" element={<>
            <NavBar />
            <CodeOfConduct />
          </>} />
          <Route path="/websiteaccessibility" element={<>
            <NavBar />
            <WebsiteAccessibility />
          </>} />
          <Route path="/admin" element={<>
            {/* <Sidebar /> */}
            <Admin />
          </>} />
          <Route path="/pendinginvites" element={<>
            {/* <Sidebar /> */}
            <PendingInvites />
          </>} />

        </Routes>
    </div>
  );
}

export default App;


// function App() {
//   return (
//     <div className="App">
//       <BrowserRouter>
//         <NavBar />
//         <Routes>
//           <Route path="/" element={<>
//             <Banner />
//             <AboutUs />
//             <HowItWorks />
//             <Categories />
//             <Download />
//             <Footer />
//             <Copyright />
//           </>} />
//           <Route path="/getinvited" element={<GetInvited />} />
//         </Routes>
//       </BrowserRouter>
//     </div>
//   );
// }

// function App() {
//   return (
//     <div className="App">
//             <Banner />
//             <AboutUs />
//             <HowItWorks />
//             <Categories />
//             <Download />
//             <Footer />
//             <Copyright />
//             <GetInvited />
//     </div>
//   );
// }
