import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';


import step1 from "../assets/img/step1.png"
import step2 from "../assets/img/step2.png"
import step3 from "../assets/img/step3.png"
import step4 from "../assets/img/step4.png"
import step5 from "../assets/img/step5.png"
import step6 from "../assets/img/step6.png"
import stepsArrow from "../assets/img/stepsarrow.png"
import mobileArrow from "../assets/img/mobilearrow.png"
import useMediaQuery from '@mui/material/useMediaQuery';

const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    boxShadow: 'none'
    // color: theme.palette.text.secondary,
  }));

export const HowItWorks = () => {
    const isSmallScreen = useMediaQuery('(max-width: 899px)');
    const isLargeScreen = useMediaQuery('(min-width: 900px)');

    return (

        <Box sx={{ flexGrow: 1 }}>
        <div className="how-it-works-title">
            <h1>How It Works</h1>
        </div>
            {/* Step 1 */}
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={1.75} sm={3.5} md={4}>
                    <Item>
                        <div className="step-container">
                            <img className="steps-icon" src={step1} alt="Step 1" />
                            <h2>Download App</h2>
                            <p>Access the revolutionary fashion ecosystem by downloading the 99 Yards app.</p>
                        </div>
                    </Item>
                </Grid>

                {isSmallScreen && (
                    <Grid item xs={0.5} sm={1} md={0.3} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Item>
                            <div className="step-container">
                                <img className="mobile-arrow" src={mobileArrow} alt="Steps Arrow" />
                            </div>
                        </Item>
                    </Grid>
                )}

                {isLargeScreen && (
                    <Grid item xs={1} sm={1} md={0.5} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Item>
                            <div className="step-container">
                                <img className="fullscreen-arrow" src={stepsArrow} alt="Steps Arrow" />
                            </div>
                        </Item>
                    </Grid>
                )}


                {/* Step 2 */}
                <Grid item xs={1.75} sm={3.5} md={3}>
                    <Item>
                        <div className="step-container">
                            <img className="steps-icon" src={step2} alt="Step 2" />
                            <h2>Set Up Profile</h2>
                            <p>Effortlessly showcase your skills and expertise by setting up your profile.</p>
                        </div>
                    </Item>
                </Grid>

                {isLargeScreen && (
                    <Grid item xs={1} sm={0.2} md={0.5} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Item>
                            <div className="step-container">
                                <img className="fullscreen-arrow" src={stepsArrow} alt="Steps Arrow" />
                            </div>
                        </Item>
                    </Grid>
                )}

                {/* Step 3 */}
                <Grid item xs={1.75} sm={3.5} md={4}>
                    <Item>
                        <div className="step-container">
                            <img className="steps-icon" src={step3} alt="Step 3" />
                            <h2>Set Your Offers</h2>
                            <p>Attract potential clients and collaborators by setting your offers and services.</p>
                        </div>
                    </Item>
                </Grid>
                {isSmallScreen && (
                    <Grid item xs={0.5} sm={1} md={0.3} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Item>
                            <div className="step-container">
                                <img className="mobile-arrow" src={mobileArrow} alt="Steps Arrow" />
                            </div>
                        </Item>
                    </Grid>
                )}

                {/* Step 4 */}
                <Grid item xs={1.75} sm={3.5} md={4}>
                    <Item>
                        <div className="step-container">
                            <img className="steps-icon" src={step4} alt="Step 4" />
                            <h2>Get Booked for Services</h2>
                            <p>Streamline your booking process by receiving bookings directly through the app.</p>
                        </div>
                    </Item>
                </Grid>


                {isLargeScreen && (
                    <Grid item xs={1} sm={0.2} md={0.5} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Item>
                            <div className="step-container">
                                <img className="fullscreen-arrow" src={stepsArrow} alt="Steps Arrow" />
                            </div>
                        </Item>
                    </Grid>
                )}

                {/* Step 5 */}
                <Grid item xs={1.75} sm={3.5} md={3}>
                    <Item>
                        <div className="step-container">
                            <img className="steps-icon" src={step5} alt="Step 5" />
                            <h2>Build Portfolio and Relationships</h2>
                            <p>Expand your professional network and portfolio by easily connecting with others in the industry.</p>
                        </div>
                    </Item>
                </Grid>

                {/* fullscreen arrow */}
                {isSmallScreen && (
                    <Grid item xs={0.5} sm={1} md={0.3} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Item>
                            <div className="step-container">
                                <img className="mobile-arrow" src={mobileArrow} alt="Steps Arrow" />
                            </div>
                        </Item>
                    </Grid>
                )}

                {isLargeScreen && (
                    <Grid item xs={1} sm={0.2} md={0.5} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Item>
                            <div className="step-container">
                                <img className="fullscreen-arrow" src={stepsArrow} alt="Steps Arrow" />
                            </div>
                        </Item>
                    </Grid>
                )}

                {/* Step 6 */}
                <Grid item xs={1.75} sm={3.5} md={4}>
                    <Item>
                        <div className="step-container">
                            <img className="steps-icon" src={step6} alt="Step 6" />
                            <h2>Get Paid in Minutes</h2>
                            <p>Receive timely payments for your services, completing the seamless process from concept to catwalk.</p>
                        </div>
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
    
}
