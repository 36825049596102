// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDdr0CPaUgqyWfqzcGUsqZPvNTr6ul9Mbo",
    authDomain: "fashionapp99.firebaseapp.com",
    databaseURL: "https://fashionapp99-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "fashionapp99",
    storageBucket: "fashionapp99.appspot.com",
    messagingSenderId: "454460666537",
    appId: "1:454460666537:web:4cc24b801a540a27c4afb8",
    measurementId: "G-G2WH1FK92P"
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const auth = getAuth(app);
export { auth };
