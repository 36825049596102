import * as React from 'react';

export const PendingInvites = () => {
    const [showInfo, setShowInfo] = React.useState([]);

    const handleNameClick = (index) => {
        setShowInfo((prevShowInfo) => {
            const updatedShowInfo = [...prevShowInfo];
            updatedShowInfo[index] = !updatedShowInfo[index];
            return updatedShowInfo;
        });
    };

    const invites = [
        {
            name: 'John Doe',
            email: 'johndoe123@gmail.com',
            phoneNumber: '123-456-7890',
            instagram: 'instagram.com/johndoe123'
        },
        {
            name: 'Jane Smith',
            email: 'janesmith456@gmail.com',
            phoneNumber: '098-765-4321',
            instagram: 'instagram.com/janesmith456'
        },
    ];

    React.useEffect(() => {
        setShowInfo(new Array(invites.length).fill(false));
    }, [invites.length]);

    return (
        <div className="pending-invites-container">
            <button className="back-button" onClick={() => window.location.href = '/admin'}>Go Back</button>
            <div className="pending-invites-list">
                <div className="pending-title">
                    <h1>Pending Invites</h1>
                </div>
                {invites.map((invite, index) => (
                    <div className="pending-invite" key={index}>
                        <h2 onClick={() => handleNameClick(index)} style={{ cursor: 'pointer' }}>{invite.name}</h2>
                        {showInfo[index] && (
                            <div className="pending-info-container">
                                <h4>Email: {invite.email}</h4>
                                <h4>Phone Number: {invite.phoneNumber}</h4>
                                <h4>Instagram: {invite.instagram}</h4>
                            </div>
                        )}
                        <div className="pending-invite-buttons">
                            <button className="pending-accept-button">Accept</button>
                            <button className="pending-reject-button">Reject</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
