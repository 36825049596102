import mastercard from '../assets/img/MasterCard.png'
import visa from '../assets/img/Visa.png'
import paypal from '../assets/img/Paypal.png'
import instaFooter from '../assets/img/footerinsta.png'
import xFooter from '../assets/img/footer-x.png'
import linkedinFooter from '../assets/img/footer-linkedin.png'
import tiktokFooter from '../assets/img/tiktokfooter.svg'
import pintrestFooter from '../assets/img/pinterestfooter.svg'
import redditFooter from '../assets/img/redditfooter.svg'

export const Copyright = () => {
    return(
        <div className='copyright-container'>
            <div className='payment-sections'>
                <img className='payment' src={mastercard} alt='MasterCard logo' />
                <img className='payment' src={visa} alt='Visa logo' />
                <img className='payment' src={paypal} alt='PayPal logo' />
            </div>
            <div className='copyright-section'>
                <p>Copyright ©2023, <b>99 YARDS</b>. All rights reserved</p>
                <div className="mobile-social-section">
                        <h1>Follow Us</h1>
                        <div className='footer-social-icons'>
                            <a href="https://www.instagram.com/99yardsapp/">
                                <img className='first-footer-icon' src={instaFooter} alt="Instagram"/>
                            </a>
                            <a href="https://twitter.com/99yardsapp">
                                <img className='footer-icon' src={xFooter} alt="X"/>
                            </a>
                            <a href="https://www.linkedin.com/company/99-yards">
                                <img className='footer-icon' src={linkedinFooter} alt="LinkedIn"/>
                            </a>
                            <a href="https://www.tiktok.com/@99yardsapp">
                                <img className='footer-icon' src={tiktokFooter} alt="TikTok"/>
                            </a>
                            <a href="https://www.pinterest.com/99YARDSapp/">
                                <img className='footer-icon' src={pintrestFooter} alt="Pinterest"/>
                            </a>
                            <a href="https://www.reddit.com/user/99YARDS/">
                                <img className='footer-icon' src={redditFooter} alt="Reddit"/>
                            </a>
                        </div>
                    </div>
            </div>
        </div>
    )
}