import React from 'react';

const Dashboard = () => {
  return (
    <div>
      {/* <h1>Welcome to the Dashboard</h1> */}
      {/* <h1>Welcome to the Dashboard</h1> */}
      <h1>Welcome to the Dashboard</h1>
      <h1>Welcome to the Dashboard</h1>
      {/* Add content here */}
    </div>
  );
};

export default Dashboard;
