import instagramIcon from "../assets/img/instagram-icon.svg"
// import facebookIcon from "../assets/img/facebook-icon.svg"
// import tiktokIcon from "../assets/img/tiktok-icon.svg"
// import youtubeIcon from "../assets/img/youtube-icon.svg"
// import snapchatIcon from "../assets/img/snapchat-icon.svg"
// import xIcon from "../assets/img/x-icon.svg"
import linkedInIcon from "../assets/img/linkedin-icon.svg"
// import pintrestIcon from "../assets/img/pintrest-icon.svg"
import redditIcon from "../assets/img/reddit-icon.svg"
// import getInvitedArrowBlue from "../assets/img/getinvitedarrow.png"
// import getInvitedArrowWhite from "../assets/img/getinvitedarrow1.png"


import garmentServiceHero from "../assets/img/garmentservices-hero.png"
import hairStylistHero from "../assets/img/hairstylist_hero.png"
import makeupArtistHero from "../assets/img/makeupartist_hero.png" 
import modelHero from "../assets/img/model_hero.png"
import jewelryServicesHero from "../assets/img/jewelryservices_hero.png"
import photographerHero from "../assets/img/photographer_hero.png"
import recruiterHero from "../assets/img/recruiter_hero.png"
import socialMediaHero from "../assets/img/socialmediainfluencer_hero.png"
import factoryHero from "../assets/img/factory_hero.png"
import retoucherHero from "../assets/img/retoucher_hero.png"
import brandsHero from "../assets/img/brands_hero.png"
import clothingDesignerHero from "../assets/img/clothingdesigner_hero.png"
import fashionStylistHero from "../assets/img/fashionstylist_hero.png"
import React, { useState, useEffect } from "react";
// import Marquee from "react-fast-marquee"

// import garmentServiceGuy from "../assets/img/garmentservice_guy.svg"
// import bannerBackgroundImage from "../assets/img/banner-picture-background.svg"

// import arrowCircle from "../assets/img/arrowcircle.svg"

    export const Banner = () => {
        const [heroIndex, setHeroIndex] = useState(0);
        
        const heroImages = [
            garmentServiceHero,
            brandsHero,
            factoryHero,
            hairStylistHero,
            makeupArtistHero,
            modelHero,
            jewelryServicesHero,
            photographerHero,
            recruiterHero,
            socialMediaHero,
            retoucherHero,
            clothingDesignerHero,
            fashionStylistHero,
        ];

        useEffect(() => {
            const interval = setInterval(() => {
                setHeroIndex((prevIndex) => (prevIndex + 1) % heroImages.length);
            }, 5000);
        
            return () => clearInterval(interval);
        }, [heroImages.length]);
        
        // const fadeInAnimation = {
        //     opacity: "100%",
        //     animation: "fade-in 0.5s ease-in-out forwards",
        // };
        // function changeImage(newImageIndex) {
        //     const imageElement = document.querySelector('.banner-image');
        //     imageElement.classList.add('fadeOut');
          
        //     setTimeout(() => {
        //       imageElement.src = heroImages[newImageIndex];
        //       imageElement.classList.remove('fadeOut');
        //     }, 1000);
        //   }

        return (
            <section className="home" id="home">
{/* 
<div className="marquee-container">
    <Marquee gradient={false} speed={80}>
        <div className="marquee-text">
            <h1 style={{ color: "#07133b", marginRight: "30px", fontWeight: "bold", display: "inline", fontSize:16 }}>
                🎉 We Just Launched! 🎉
            </h1>
            <h2 style={{ color: "#07133b", marginRight: "30px", display: "inline", fontSize:16 }}>
                🚀 Discover the Ultimate Fashion Platform! 🚀
            </h2>
            <h2 style={{ color: "#07133b", marginRight: "30px", display: "inline",fontSize:16  }}>
                Join Us Now and Be Part of the Revolution! 🌟
            </h2>
        </div>
    </Marquee>
</div> */}

                <div className="banner-container">
                    
                    <div className="scroll-down-arrow">
                        <h3> ------ Scroll Down -----{">"} </h3>
                    </div>
                    <div className="mobile-container">
                        <div className="mobile-elements">

                            <div className="banner-title-mobile">
                                <h1>99 YARDS</h1>
                            </div>
                            <div className="social-icons-section-mobile">
                                <div className="line-break" />
                                    <a href="https://www.instagram.com/99yardsapp/">
                                        <img className="social-icon" src={instagramIcon} alt="Instagram" />
                                    </a>
                                <div className="line-break" />
                                    <a href="https://www.reddit.com/user/99YARDS/">
                                        <img className="social-icon" src={redditIcon} alt="Reddit" />
                                    </a>    
                                <div className="line-break" />
                                    <a href="https://www.linkedin.com/company/99-yards">
                                        <img className="social-icon" src={linkedInIcon} alt="LinkedIn" />
                                    </a>
                                <div className="line-break" /></div>
                        </div>
                    </div>
                    <div className="banner-text">
                        <div className="banner-title">
                            <h1>99 YARDS</h1>
                        </div>
                        <br />
                        <p>
                            Digital platform providing an integrated ecosystem for the{" "}
                            <b>Fashion Industry</b> and its <b>Professionals </b>
                            
                            from <b>Concept To Catwalk</b>{" "}
                        </p>
                        <a className="button-link" href="/#download" rel="noopener noreferrer">
                            <button className="get-invited-button">
                                Get the App 
                                <div className="get-invited-arrow" alt="Get Invited" />
                            </button>
                        </a>
                    </div>
                    <div className="banner-picture-part">
                    <img
                        key={heroIndex}
                        className="banner-image"
                        src={heroImages[heroIndex]}
                        alt="Banner"
                        
                    />
                    </div>
                    <div className="social-icons-section">
                        {/* <div className="line-break" />
                        <p>EN</p> */}
                        <div className="line-break" />
                        <a href="https://www.instagram.com/99yardsapp/">
                            <img className="social-icon" src={instagramIcon} alt="Instagram" />
                        </a>
                        {/* <div className="line-break" />
                        <a href="https://www.pinterest.com/99YARDSapp/">
                            <img className="social-icon" src={pintrestIcon} alt="Pintrest" />
                        </a> */}
                        <div className="line-break" />
                        <a href="https://www.reddit.com/user/99YARDS/">
                            <img className="social-icon" src={redditIcon} alt="Reddit" />
                        </a>    
                        <div className="line-break" />
                        {/* <a href="https://twitter.com/99yardsapp">
                            <img className="social-icon" src={xIcon} alt="X" />
                        </a>    
                        <div className="line-break" /> */}
                        {/* <a href="https://www.tiktok.com/@99yardsapp">
                            <img className="social-icon" src={tiktokIcon} alt="TikTok" />
                        </a>
                        <div className="line-break" /> */}

                        <a href="https://www.linkedin.com/company/99-yards">
                            <img className="social-icon" src={linkedInIcon} alt="LinkedIn" />
                        </a>
                        <div className="line-break" />
                        {/* <img className="social-icon" src={youtubeIcon} alt="YouTube" />
                        <div className="line-break" /> */}
                    </div>

                </div>
            </section>
        );
    };

    //     return (
    //         <section className="home" id="home">
    //             <div className="banner-container">
    //                 <div className="scroll-down-arrow">
    //                     <h3> ------ Scroll Down -----{">"} </h3>
    //                 </div>
    //                 <div className="banner-text">
    //                     <h1>99 YARDS</h1>
    //                     <br />
    //                     <p>
    //                         Digital platform providing an integrated ecosystem for the{" "}
    //                         <b>Fashion Industry</b> and its <b>Professionals.</b>
    //                         <br></br>
    //                         <b>From Concept To Catwalk.</b>{" "}
    //                     </p>
    //                     <a href="#getinvited">
    //                         <button className="get-invited-button">
    //                             Get Invited
    //                             <img className="get-invited-arrow" src={getInvitedArrow} />
    //                         </button>
    //                     </a>
    //                 </div>
    //                 <div classname="banner-picture-part">
    //                     <img
    //                         className="banner-image"
    //                         src={heroImages[heroIndex]}
    //                         alt="Banner"
    //                     />
    //                 </div>
    //                 <div className="social-icons-section">
    //                     <div className="line-break" />
    //                     <p>EN</p>
    //                     <div className="line-break" />
    //                     <a href="https://www.instagram.com/99yardsapp/">
    //                         <img className="social-icon" src={instagramIcon} alt="Instagram" />
    //                     </a>
    //                     <div className="line-break" />
    //                     <img className="social-icon" src={facebookIcon} alt="Facebook" />
    //                     <div className="line-break" />
    //                     <img className="social-icon" src={xIcon} alt="X" />
    //                     <div className="line-break" />
    //                     <img className="social-icon" src={snapchatIcon} alt="Snapchat" />
    //                     <div className="line-break" />
    //                     <img className="social-icon" src={tiktokIcon} alt="TikTok" />
    //                     <div className="line-break" />
    //                     <img className="social-icon" src={youtubeIcon} alt="YouTube" />
    //                     <div className="line-break" />
    //                 </div>
    //             </div>
    //         </section>
    //     );
    // };
