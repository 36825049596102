import * as React from 'react';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";


import instaFooter from '../assets/img/footerinsta.png'
import xFooter from '../assets/img/footer-x.png'
import linkedinFooter from '../assets/img/footer-linkedin.png'
import tiktokFooter from '../assets/img/tiktokfooter.svg'
import pintrestFooter from '../assets/img/pinterestfooter.svg'
import redditFooter from '../assets/img/redditfooter.svg'

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 'auto',
//     maxHeight: 600,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
//     overflow: 'scroll'
// };


export const Footer = () => {
    // const [openModal1, setOpenModal1] = React.useState(false);
    // const [openModal2, setOpenModal2] = React.useState(false);
    // const [openModal3, setOpenModal3] = React.useState(false);
    // const [openModal4, setOpenModal4] = React.useState(false);


    // const handleOpenModal1 = () => setOpenModal1(true);
    // const handleCloseModal1 = () => setOpenModal1(false);
    // const handleOpenModal2 = () => setOpenModal2(true);
    // const handleCloseModal2 = () => setOpenModal2(false);
    // const handleOpenModal3 = () => setOpenModal3(true);
    // const handleCloseModal3 = () => setOpenModal3(false);
    // const handleOpenModal4 = () => setOpenModal4(true);
    // const handleCloseModal4 = () => setOpenModal4(false);

    

    return (
        <div className="footer-container">
            <div className='newsletter-section'>
                <div className='newsletter-text'>
                    <h4>Newsletter</h4>
                    <h3>Stay in touch and never miss an update</h3>
                    <p>Keep up to date on the latest and greatest news of <strong>99YARDS</strong></p>
                </div>
                <div className='newsletter-input'>
                    <input type='text' placeholder='Email Address' />
                    <button>Sign Up</button>
                </div>
            </div>
            <div className="footer-sections">
                {/* <div className="sections-1-2"> */}
                    <div className="section">
                        <h1>About</h1>
                        <a href='#aboutus'>
                            <p>About Us</p>
                        </a>
                    </div>
                    <div className="section">
                        <h1>Help</h1>
                        {/* <a href="https://sf2osh4qouo.typeform.com/to/xENd3ZY5?typeform-source=localhost" target="_blank" rel="noopener noreferrer">
                            <p>Invitation Code</p>
                        </a> */}
                        <a href="mailto:admin@99yards.app"><p>Contact Us</p></a>
                    </div>
                {/* </div> */}
                {/* <div className="sections-3-4"> */}
                    <div className="section">
                        <h1>Regulations</h1>
                        <div>
                        <Link to="/termsandconditions"><p>Terms & Conditions</p></Link>
                        <Link to="/privacypolicy"><p>Privacy Policy</p></Link>
                        <Link to ="/codeofconduct"><p>Code of Conduct</p></Link>
                        <Link to ="/websiteaccessibility"><p>Website Accessibility</p></Link>

                            {/*<button className="modal-button" onClick={handleOpenModal1}>Terms and Conditions</button>
                             <Modal
                                open={openModal1}
                                onClose={handleCloseModal1}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                    <p className='modal-words'>Terms and Conditions</p>
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        THE AGREEMENT: The use of this website and services on this website provided by 99 YARDS  (99Yards.app referred to as "Website, company or platform") are subject to the following Terms & Conditions, all parts and sub-parts of which are specifically incorporated by reference here. This agreement shall govern the use of all pages on this website (hereinafter collectively referred to as "Website") and any services provided by or on this website ("Services").
                                        DEFINITIONS
                                        "Agreement" denotes to this Terms and Conditions and the Privacy Policy and other documents provided to you by the website.
                                        "We," "us," and "our" are references to 99YARDS.APP.
                                        "User," "You," and "your" denotes the person who is accessing the website for taking or availing of any service from us. User shall include the company, partnership, sole trader, person, body corporate, or association taking services of this website.
                                        "Artist" is a person who sells his/her services through the platform.
                                        A "Buyer" is a person who buys the service through the platform.
                                        " Website" shall mean and include 99Yards.app and any successor Website of the Company or any of its affiliates.
                                        Parties: Collectively, the parties to this Agreement (We and You) will be referred to as Parties.
                                        ASSENT & ACCEPTANCE
                                        PLEASE READ THESE TERMS OF USE, OUR PRIVACY POLICY, AND ALL APPLICABLE SUPPLEMENTAL TERMS (COLLECTIVELY, THE "TERMS") CAREFULLY, AS THEY CONTAIN TERMS AND CONDITIONS THAT IMPACT YOUR RIGHTS, OBLIGATIONS, AND REMEDIES IN CONNECTION WITH YOUR USE OF THE SERVICES AND CONTENT. FOR EXAMPLE, THE TERMS INCLUDE:

                                        YOUR OBLIGATION TO COMPLY WITH ALL APPLICABLE LAWS AND REGULATIONS.
                                        LIMITATIONS OF OUR LIABILITY TO YOU; AND
                                        A REQUIREMENT THAT YOU PURSUE CLAIMS OR SEEK RELIEF AGAINST US (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS, RATHER THAN AS A PARTICIPANT IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING.
                                        YOUR ACCESS TO AND USE OF THE SERVICES IS CONDITIONED ON YOUR ACCEPTANCE OF AND COMPLIANCE WITH ALL APPLICABLE TERMS. If you do not agree to these Terms or our Privacy Policy, then please cease using the Services immediately. We reserve the right to change these Terms at any time (see "Changes to these Terms" below.) By accessing, browsing, and/or using the Services after updates to these Terms have been posted, you agree to be bound by the updated Terms. THESE TERMS AND OUR PRIVACY POLICY CONSTITUTE A BINDING AGREEMENT BETWEEN YOU AND 99 Yards.
                                        Consequences of Non-Compliance
                                        Your failure to comply with the Terms may result in the suspension or termination of your account and/or access to the Services and may subject you to civil and criminal penalties.
                                        ELIGIBILITY 
                                        To access our Services through our website, you must be a legal entity or an individual of eighteen (18) years of age or older who can form legally binding contracts. To become a Registered User, you must accept all the terms and conditions in, incorporated by reference in, and linked to, these Terms and conditions. By becoming a Registered User, you agree to (1) Abide by the Terms and conditions and the processes, procedures, and guidelines described throughout the website; (2) Be financially responsible for your use of the website and the purchase or delivery of services; and (3) Perform your obligations as specified by any Job Agreement that you accept, unless such obligations are prohibited by law or by the Terms and conditions. 99 YARDS reserves the right, in its sole discretion, to refuse, suspend, or terminate Services to anyone.
                                        REGISTRATION
                                        To become a Registered User and to access Services, you must register for an Account. You agree to provide true, accurate, and complete information as prompted by the registration form and all forms you access on the website and to update this information to maintain its truthfulness, accuracy, and completeness.
                                        ACCOUNTS AND PROFILES
                                        General. Once you have registered with the website as a Registered User, the website will create your Account with Company and associate it with an account number. You may create a profile under your Account, Username, and Password. During registration, you will be asked to choose a username and password for the Account. As a Registered User, you agree, and you are entirely responsible for safeguarding and maintaining the confidentiality of the username and password you use to access this website. You authorize Company to assume that any person using the website with your username and password is you or is authorized to act for you. You agree to notify us immediately if you suspect any unauthorized use of the account.
                                        TAXES
                                        You are responsible for paying any taxes, including any services or value-added taxes, which may be applicable depending on the jurisdiction of the services provided.
                                        Depending on your residency or location, you may be subject to certain ad valorem or other taxes (for example, GST) on certain fees that we charge. These taxes will be added to fees billed to you, if applicable.
                                        Artists are 100% responsible for recording and paying their own tax in their countries based on the income they earn from 99 YARDS .
                                        You acknowledge that you must comply with your obligations under income tax provisions in your jurisdiction.
                                        BUYER AND ARTIST
                                        Job Agreement: The engagement, contracting, and management of a Job are between a Buyer and an Artist. Upon acceptance of a Quote, the Buyer agrees to purchase, and the Artist agrees to deliver, the services and related deliverables in accordance with the following agreements: (a) the Job Agreement between Buyer and Artist including the Quote, Job Description, and other terms and conditions as communicated between Buyer and Artist on the website or otherwise, (b) these Terms of Service, and (c) any other content uploaded to the Website by Company (collectively, the "Job Agreement"). You agree not to enter any contractual provisions in conflict with these Terms of Service. Any provision of a Job Agreement in conflict with these Terms of Service is void. Buyer is responsible for managing, inspecting, accepting, and paying for satisfactory services and deliverables in accordance with the Job Agreement in a timely manner. Artist is responsible for the performance and quality of the services in accordance with the Job Agreement in a timely manner. Buyer and Artist each covenant and agrees to act with good faith and fair dealing in performance of the Job Agreement.
                                        Independence: Buyer and Artist each acknowledge and agree that their relationship is that of independent contractors. The Artist shall perform services as an independent contractor, and nothing in these Terms of Service shall be deemed to create a partnership, joint venture, agency, or Buyer-employee relationship between Artist and Buyer or between Company and any Buyer or Artist.
                                        SERVICE FEES
                                        The company deducts one or more of the following fees, as applicable, from users using the Company Billing and Payment Services:

                                        We charge 12% booking fee
                                        We charge 9% when they take out funds
                                        We reserve the right to change any fees associated with the Company at any time, at the sole discretion of the Company. No refunds of fees already paid will be given. If we exercise our right to cancel a membership as provided under these Terms of Service at any time, we will not refund the membership fee already paid.
                                        TERM; TERMINATION AND SUSPENSION
                                        These Terms and conditions shall become effective as your contractual agreement upon your use of the website and shall continue until your Account is terminated by you or Company as provided for under the terms of this section.

                                        Unless otherwise agreed to in writing between the parties, either party may terminate the contractual agreement represented by these Terms and conditions at any time upon notice to the other party. In such event, your Account is automatically terminated, and (1) Company shall continue to perform those services necessary to complete any open transaction between you and another Registered User; and (2) You shall continue to be obligated to pay any amounts accrued but unpaid as of the date of termination to Company for any service and to any Artist for any services.
                                        Any termination of an Account will automatically lead to the termination of all related profiles.
                                        Without limiting our other remedies, we may issue a warning, or temporarily suspend, indefinitely suspend or terminate your Account or a Job, and refuse to provide any or all services to you if: (1) you breach the letter or spirit of any terms and conditions of these Terms and conditions or the linked policies and information incorporated herein by reference, including our written policies and procedures posted on the website; (2) we are unable to verify or authenticate any information you provide to us; or (3) we believe in our sole discretion that your actions may cause legal liability for you, our Registered Users or for Company or are contrary to the interests of the website. Once indefinitely suspended or terminated, you must not continue to use the website under the same Account, a different Account, or register under a new Account.
                                        In addition, violations of these Terms and conditions may be fully prosecuted of the law and may result in additional penalties and sanctions.
                                        Without limiting our other remedies, to the extent you engage in actions or activities which circumvent the Company Billing and Payment Services or otherwise reduce fees owed Company under these Terms and conditions, you must pay Company for all fees owed to Company and reimburse Company for all losses and costs and reasonable expenses (including attorney fees) related to investigating such breach and collecting such fees.
                                        When your Account is terminated for any reason, you may no longer have access to data, messages, files, and other material you keep on the website. The material may be deleted along with all your previous posts and proposals.
                                        USER ACCOUNT
                                        By registering for an account to use the Site or Site Services (an "Account"), by using the Site or Site Services after the Effective Date if you had an Account on the Effective Date, or by clicking to accept the Terms of Service when prompted on the Site, you agree to abide by this Agreement and the other Terms and conditions.
                                        ACCOUNT TYPES
                                        As described in this Section, there are several different Account types. Once you register for one Account type, you can add the other Account types under the same username and password. For example, if you already have an Artist Account, you can add Buyer Account (defined below) as a separate account type in settings without re-registering. You agree not to have or register for more than one Account without express written permission from us. We reserve the right to revoke the privileges of the Account or access to or use of the Site or Site Services, and those of all linked Accounts without warning if, in our sole discretion, false or misleading information has been provided in creating, marketing, or maintaining your Profile or Account.
                                        BUYER ACCOUNT
                                        You can register for an Account or add an Account type to use the Site and Site Services as a Buyer (a "Buyer Account"). 

                                        Buyers own full rights to their art after the order is completed.
                                        All communication must be kept on the 99 YARDS  website.
                                        99 YARDS the authority to delete misleading or false reviews left by buyers.
                                        ARTIST ACCOUNT

                                        Artists have the right to refuse an order without any reason.
                                        Artists can display work from previous orders on their portfolios even if the buyer does not consent.
                                        Artists can cancel and refund any order, even if halfway through the project.
                                        All communication must be kept on the 99 Yards website.
                                        Artists should not add other people's work to their portfolio and pretend it is their own; this will breach copyright laws.
                                        Artists should add correct and applicable style tags to their profile and upload work. Incorrect style tags can result in an account ban.
                                        How often do artists get paid? CLICK HERE
                                        Artists should not give false information about their location.
                                        PROHIBITED SITE USES
                                        You may not use, or encourage, promote, facilitate, or instruct, induce, or otherwise influence or cause others: (1) to use the Site or Site Services for any activities that violate any law, statute, ordinance or regulation; for any other illegal or fraudulent purpose or any purpose that is harmful to others, or (2) to transmit, store, display, distribute or otherwise make available content that is illegal, fraudulent or harmful to others. 99 YARDS has the authority to refund an order if any terms and conditions are broken by the artist or if the work that is sold is not original.
                                        EXAMPLES OF PROHIBITED USES OF THE SITE
                                        The following are examples of uses that are prohibited on the Site or when using the Site Services:
                                        Seeking, offering, promoting, supporting, or endorsing services, content, or activities that:

                                        All artwork displayed on the 99 YARDS  website and on artist profiles and explore pages are not to be reused or distributed anywhere else.
                                        Are defamatory, illegal, violent, profane, vulgar, threatening, unlawfully discriminatory, illegal, pornographic, obscene, or sexually explicit in nature.
                                        Would violate the intellectual property rights, such as and including copyrights, of another person, entity, service, product, or website.
                                        Would violate (a) the Company's Terms and Conditions, (b) the Terms and Conditions of another website or any similar contractual obligations, or (c) the academic policies of any educational institution.
                                        Regard the creation, purchase, and/or publication of a review for a third-party website.
                                        Regard the creation, publication, or distribution of "fake news," "hoax news," Deep Fake content, or similar content, which is, in Company's sole discretion, determined to be intended to mislead recipients for personal, financial, political, or other gain or advantage.
                                        Regard or promote in any way any escort services, prostitution, or sexual acts; or
                                        any conduct that is, in Company's determination, violent or harassing toward another person or endorses or encourages violence or harassment towards others, including based on the individual's or group's inclusion in a protected class as defined by applicable law.
                                        Fraudulent or misleading uses or content, including:

                                        Fraudulently billing or attempting to fraudulently bill any Client, including by (i) falsifying or manipulating or attempting to falsify or manipulate the hours, keystrokes, or mouse clicks recorded in the Company Platform, (ii) reporting, recording, or otherwise billing Clients for the time that was not actually worked, or (iii) on an hourly contract, reporting, recording, or otherwise billing hours worked by another person as hours worked by you in a way that is misleading or false.
                                        Misrepresenting your experience, skills, or information, including by representing another person's profile or parts of another person's profile, as your own.
                                        Using a profile photo that is not you misrepresents your identity or represents you as someone else or any other activity that misrepresents your identity.
                                        Impersonating any person or entity, including, but not limited to, Company representative, forum leader, or falsely stating or otherwise misrepresenting your affiliation with a person or entity.
                                        Falsely stating or implying a relationship with another User, including an Agency continuing to use a Artist's profile or information after the Artist no longer works with the agency.
                                        Falsely attributing statements to any Company representative, forum leader, guide, or host.
                                        Falsely stating or implying a relationship with Company or with another company with whom you do not have a relationship.
                                        Allowing another person to use your account, which is misleading to other Users; or
                                        Falsely stating that one Artist will perform the work on a job when another will in fact perform the work, including submitting a proposal on behalf of a Artist that is unable, unwilling, or unavailable to do the work.

                                        Expressing an unlawful preference in a job post or proposal or otherwise unlawfully discriminating on a protected basis.
                                        Inciting or encouraging violence.
                                        Posting identifying information concerning another person.
                                        Spamming other Users with proposals or invitations, including by making unsolicited contact of Users off the Company platform, or by posting the same job multiple times so that more than one version remains active at a given time.
                                        Making or demanding bribes or demanding other payments without the intention of or without providing services in exchange for the payment.
                                        Requesting or demanding free services, including requesting Artists to submit work as part of the proposal process for very little or no money or posting contests in which Artists submit work with no or very little pay, and only the winning submission is paid the full amount.
                                        Requesting a fee before allowing a User to submit a proposal.
                                        Attempting to or manipulating or misusing the feedback system, including by:

                                        withholding payment or Work Product or engaging in any other conduct for the purpose of obtaining positive feedback from another User.
                                        Attempting to coerce another User by threatening to give negative feedback.
                                        Expressing views unrelated to work, such as political, religious, or social commentary, in the feedback system.
                                        Providing anything of value to any person (including to a third-party who aids in obtaining feedback) or using any service of any type to obtain feedback; or
                                        offering services for the sole purpose of obtaining positive feedback of any kind.
                                        Duplicating or sharing accounts.

                                        Selling, trading, or giving an account to another person without Company's consent.
                                        Sharing or soliciting Means of Direct Contact with or from another User either directly through messages or by including Means of Direct Contact in a job post, profile, proposal, or other User Content prior to entering a Service Contract with such User, except as expressly allowed for Enterprise Clients.
                                        Directly or indirectly, advertising or promoting another website, product, or service or soliciting other Users for other websites, products, or services, including advertising on Company to recruit Artists and/or Clients to join an Agency or another website or company.
                                        Interfering or attempting to interfere with the proper operation of the Site or Site Services or any activities conducted on the Site, including by:

                                        bypassing any measures we may use to prevent or restrict access to the Site or any subparts of the Site, including, without limitation, features that prevent or restrict use or copying of any content or enforce limitations on use of the Site or the content therein.
                                        Attempting to interfere with or compromise, or interfering with or compromising, the system integrity or security of the servers running the Site or attempting to or deciphering any transmissions to or from the servers running the Site.
                                        Using any robot, spider, scraper, or other automated means to access the Site for any purpose without our express written permission.
                                        Collecting or harvesting any personally identifiable information, including Account names, from the Site.
                                        Attempting to or imposing an unreasonable or disproportionately large load (as determined in Company's sole discretion) on the Site's infrastructure.
                                        introducing any invalid data, virus, worm, or other harmful or malicious software code, agent, hidden procedure, routine, or mechanism through or to the Site that is designed to or known to cause to cease functioning, disrupt, disable, harm, or otherwise impair in any manner, including aesthetic disruptions or distortions, the operation of (or to allow you or any other person to access or damage or corrupt data, storage media, programs, equipment, or communications or otherwise interfere with operations of or on) the Site or any software, firmware, hardware, computer system, or network of Company or any third party;
                                        accessing or attempting to access the Site or Site Services by any means or technology other than the interface provided; or
                                        framing or linking to the Site or Site Services except as permitted in writing by Company.
                                        Conduct or actions that could jeopardize the integrity of or circumvent the Site, Site Services or Company's proprietary information, including:

                                        attempting to or reverse engineering, modifying, adapting, translating, preparing derivative works from, decompiling, or interfering with the operation of, or otherwise attempting to derive source code from, any part of the Site or Site Services unless expressly permitted by applicable law or Company; or
                                        accessing or using the Site or Site Services to build a similar service or application, identify or solicit Company Users, or publish any performance or any benchmark test or analysis relating to the Site.
                                        DISPUTES AND CANCELLATIONS
                                        99 YARDS has ultimate authority on how a dispute is resolved but we encouraged to work together to come to a solution. If you have a dispute with another Registered User from claims, demands cancellation refunds conflicts among themselves every kind and nature try to settle conflicts amongst themselves if fails you can contact customer support.
                                        USER OBLIGATIONS
                                        As a user of the Website or Services, you may be asked to register with us. When you do so, you will choose a user identifier, which may be your email address or another term, as well as a password. You may also provide personal information, including, but not limited to, your name. You are responsible for ensuring the accuracy of this information. This identifying information will enable you to use the Website and Services. You must not share such identifying information with any third party, and if you discover that your identifying information has been compromised, you agree to notify us immediately in writing. An email notification will suffice. You are responsible for maintaining the safety and security of your identifying information, as well as keeping us apprised of any changes to your identifying information. Providing false or inaccurate information or using the Website or Services to further fraud or unlawful activity is grounds for immediate termination of this agreement.
                                        ACCEPTABLE USE
                                        You agree not to use the Website or Services for any unlawful purpose or any purpose prohibited under this clause. You agree not to use the Website or Services in any way that could damage the Website, Services, or general business of 99 YARDS.app.

                                        You further agree not to use the Website or Services:
                                        To harass, abuse, or threaten others or otherwise violate any person's legal rights.
                                        To violate any of our intellectual property rights or any third party.
                                        To upload or otherwise disseminate any computer viruses or other software that may damage the property of another.
                                        To perpetrate any fraud.
                                        To engage in or create any unlawful gambling, sweepstakes, or pyramid scheme.
                                        To publish or distribute any obscene or defamatory material.
                                        To publish or distribute any material that incites violence, hate, or discrimination towards any group.
                                        To unlawfully gather information about others.
                                        INTELLECTUAL PROPERTY
                                        You agree that the Website and all Services provided by us are the property of 99 YARDS, including all copyrights, trademarks, trade secrets, patents, and other intellectual property ("Our IP"). You agree that we own all rights, title, and interest in and to the Our IP and that you will not use Our IP for any unlawful or infringing purpose. You agree not to reproduce or distribute Our IP in any way, including electronically or via registration of any new trademarks, trade names, service marks, or Uniform Resource Locators (URLs), without express written permission from us.

                                        To make the Website and Services available to you, you hereby grant us a royalty-free, non-exclusive, worldwide license to copy, display, use, broadcast, transmit and make derivative works of any content you publish, upload, or otherwise make available to the website ("Your Content"). We claim no further proprietary rights in your content.
                                        If you feel that any of your intellectual property rights have been infringed or otherwise violated by the posting of information or media by another of our users, please contact us and let us know.
                                        INDEMNIFICATION
                                        To the maximum extent permitted by law, you agree to indemnify, defend and hold harmless 9 PRO and its affiliates and/or related entities, whether direct or indirect, current, former or future, and its and their respective current, former, or future officers, directors, employees, agents, successors and assigns and related third parties (each an "Indemnified Party"), for any claims, causes of action, debts, damages, losses, costs, liabilities and expenses (including reasonable attorneys' fees) relating to or arising out of any third-party claim that (a) your use of or inability to use the Services, (b) any user postings made by you, (c) your violation of any terms of this agreement or your violation of any rights of a third-party, or (d) your violation of any applicable laws, rules or regulations, except to the extent caused by any unlawful or negligent act or omission by 99 YARDSA . 99 YARDS reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with 99 YARDS in asserting any available defenses. An Indemnified Party may participate in the defense by counsel of its own choosing, at its own cost and expense. You shall not settle any claim that adversely affects an Indemnified Party or imposes any obligation or liability on an Indemnified Party without the Indemnified Party's prior written consent.
                                        EXCLUSION OF LIABILITY
                                        You understand and agree that we (A) do not guarantee the accuracy, completeness, validity, or timeliness of information listed by us or any third parties, and (B) shall not be responsible for any materials posted by us or any third party. You shall use your judgment, caution, and common sense in evaluating any prospective methods or offers and any information provided by us or any third party.
                                        Further, we shall not be liable for direct, indirect, consequential, or any other form of loss or damage that may be suffered by a user using the 9pro.app Website including loss of data or information or any kind of financial or physical loss or damage.
                                        In no event shall 99 YARDS, nor its Owner, directors, employees, partners, agents, suppliers, or affiliates, be accountable for any indirect, incidental, special, eventful, or exemplary costs, including without limitation, loss of proceeds, figures, usage, goodwill, or other intangible losses, consequential from (i) your use or access of or failure to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content attained from the Service; and (iv) unlawful access, use or alteration of your transmissions or content, whether or not based on guarantee, agreement, domestic wrong (including carelessness) or any other lawful concept, whether or not we've been aware of the possibility of such damage, and even if a cure set forth herein is originated to have futile of its important purpose.
                                        MODIFICATION & VARIATION
                                        We may, from time to time and at any time without notice to you, modify this agreement. You agree that we have the right to modify this Agreement or revise anything contained herein. You further agree that all modifications to this agreement are in full force and effect immediately upon posting on the website and that modifications or variations will replace any prior version of this agreement unless prior versions are specifically referred to or incorporated into the latest modification or variation of this agreement.
                                        NO WARRANTIES
                                        You agree that your use of the Website and Services is at your sole and exclusive risk and that any Services provided by us are on an "As Is" basis. We hereby expressly disclaim any express or implied warranties of any kind, including but not limited to the implied warranty of fitness for a particular purpose and the implied warranty of merchantability. We make no warranties that the Website or Services will meet your needs or that the Website or Services will be uninterrupted, error-free, or secure. We also make no warranties as to the reliability or accuracy of any information on the website or obtained through the Services. You agree that any damage that may occur to you, through your computer system, or because of the loss of your data from your use of the Website or Services is your sole responsibility and that we are not liable for any such damage or loss.
                                        LIMITATION ON LIABILITY
                                        We are not liable for any damages that may occur to you because of your use of the Website or Services to the fullest extent permitted by law. This section applies to any claims by you, including, but not limited to, lost profits or revenues, consequential or punitive damages, negligence, strict liability, fraud, or torts of any kind.
                                        SEVERABILITY
                                        If any provision of these Terms and conditions is determined to be unlawful, void, or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms and conditions, such determination shall not affect the validity and enforceability of any other remaining provisions.
                                        CONTACTING US
                                        If you would like to contact us to understand more about terms or wish to contact us concerning any matter, you may do so via the contact us or email us at moirai9app@gmail.com. 
                                        99 YARDS 
                                        United States 
                                        This document was last updated on January 8, 2022
                                    </Typography>
                                </Box>
                            </Modal>
                        </div>
                        <div>
                            <button className='modal-button' onClick={handleOpenModal2}>Security and Privacy</button>
                            <Modal
                                open={openModal2}
                                onClose={handleCloseModal2}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Security and Privacy
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}> 
                                        We know that in this digital age, your privacy is important. This Privacy Policy reflects our commitment to protect personal data and the choices we offer you regarding how your data is used. We welcome you to read more about how we keep your information safe, as well as how you can exercise your rights. In addition, our Privacy policy covers our treatment of data that may be personal to you.

                                        ‍We will review, update, and amend these policies from time to time consistent with our business needs and technology. We encourage you to check back periodically for new updates or changes. Your continued use of the service makes up your acceptance of any change to this Privacy Policy. We are the data controller of your information. We handle and process all data on behalf of our customers
                                        You may likewise decide not to give us "discretionary" Personal Data; however please remember that without it, we will most likely be unable to provide you with the full scope of our administrations or with the best client experience when utilizing our Services.
                                        This Privacy Policy (“Privacy Policy”) describes how 99 YARDS. will gather, use, and maintain your Personal Information on the 99YARDS.app. It will also explain your legal rights with respect to that information.
                                        By using the website or services, you confirm that you have read and understand this Privacy Policy, and our Terms (together referred to herein as the “Agreement”). The Agreement governs the use of 99YARDS.app. 99 YARDS  will collect, use, and maintain information consistent with the Agreement.

                                        We recommend that you read this Policy in full to ensure you are fully informed. However, if you only want to access a particular section of this Policy, then you can click on the relevant link below to jump to that section.

                                        Privacy Policy 

                                        What private data do we collect from the people who visit our website? 

                                        Do Not Track 

                                        How do we use your details? 

                                        How we use cookies 

                                        First-party cookies 

                                        Third-party cookies

                                        What are your choices regarding cookies? 

                                        Google Analytics Cookies 

                                        California Consumer Rights 

                                        According to caloppa, we agree to the following: 

                                        Coppa (children online privacy protection action) 
                                        GDPR-Customer data processing appendix: 
                                        GDPR-EU data protection law 

                                        Your Legal Rights 

                                        You may have the following rights: - 

                                        How do we protect your details? 
                                        Can-spam act 
                                        We accumulate your email to be able to: 
                                        Limitation of liability 
                                        Indemnification 
                                        Governing Law and Jurisdiction 
                                        Changes to this privacy notice 
                                        Contacting us 
                                        What private data do we collect from the people who visit our website?
                                        When you use the Service, you may provide us with information about you. This may include your name and contact information, financial information to make or receive payment for services obtained through the 99YARDS platform, or information to help us fill out tax forms. When you use the Service, we may also collect information related to your use of the Service and aggregate this with information about other users. 

                                        Account Data: To use certain features (like to sell or buy services), you need to create a user account. 

                                        Buyer account: When you create or update your account, we collect and store the data you provide, like your First name, Last name, email address, password, and assign you a unique identifying number (“Account Data”).
                                        Artist Account: When you create an artist account, we collect and store the data you provide, like your First name, Last name, email address, password, Location (State/Country), and assign you a unique identifying number (“Account Data”).
                                        Personal Data: Personal Data is information that can be used to identify you specifically, including your name, email address, telephone number, or demographic information like your hometown. You consent to give us this information by providing it to us voluntarily on our website or any mobile application. Your decision to disclose this data is entirely voluntary. You are under no obligation to provide this information, but your refusal may prevent you from accessing certain benefits from our website.
                                        Profile Data: You can also choose to provide profile information like a photo, website link, social media profiles, or other data. Your Profile Data will be publicly viewable by others.
                                        Financial Data: Financial data is related to your payment methods, such as credit card or bank transfer details. We collect financial data to allow you to purchase, order, or exchange services from our website. We store limited financial data. Most financial data is transferred to our payment processor, Stripe, and you should review these processors' Privacy Policy to determine how they use, disclose, and protect your financial data. 

                                        Stripe Privacy Policy can be found here https://stripe.com/gb/privacy
                                        Contact information. An Authorized User is required to provide some contact information (e.g., an email address) when making an account on the Services.
                                        General Audience Service: The Service is general audience and intended for users 18 and older. We do not knowingly collect Personal Information from anyone younger than age 18. If we become aware that a child younger than 18 has provided us with Personal Information, we will use commercially reasonable efforts to delete such information from our files. If you are the parent or legal guardian of a child younger than age 18 and believe that 99 YARDS has collected Personal Information from your child, please contact us.

                                        Do Not Track
                                        Currently, various browsers — such as Internet Explorer, Firefox, and Safari — offer a “do not track” or “DNT” option that relies on a technology known as a DNT header, which sends a signal to Web sites’ visited by the user about the user's browser DNT preference setting. 99YARDS does not currently commit to responding to browsers' DNT signals with respect to the Company's Web sites, in part, because no common industry standard for DNT has been adopted by industry groups, technology companies or regulators, including no consistent standard of interpreting user intent. 99 YARDS  takes privacy and meaningful choice seriously and will make efforts to continue to monitor developments around DNT browser technology and the implementation of a standard.
                                        How do we use your details?
                                        We may utilize the data we procure from you when you enlist, make a buy, join our bulletin, respond to an examination or promoting correspondence, peruse the site, or utilize specific other site includes in the following ways:

                                        Create your account; and
                                        Deliver any services purchased by you to you; and
                                        Correspond with you; and
                                        Compile anonymous statistical data for our own use or for a third party's use; and
                                        Assist law enforcement as necessary; and
                                        Prevent fraudulent activity on our website or mobile app; and
                                        Analyze trends to improve our website and offerings.
                                        To fulfill or meet the reason you provided the information (e.g., to help provide our Site services to you).
                                        To personalize and develop our site and the services we provide you and improve our offerings.
                                        To provide certain features or functionality of the services on the site.
                                        For marketing and promotions.
                                        To create, maintain, customize, and secure your account with us.
                                        To provide you with support, to communicate with you and respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses.
                                        To personalize your experience and to deliver content and services relevant to your interests.
                                        To help maintain the safety, security, and integrity of our site, services, databases, and other technology assets and business.
                                        For testing, research, analysis, and website development, including to develop and improve our site and services.
                                        To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.
                                        To prevent illegal activity, fraud, and abuse.
                                        Where we must play out the agreement we are going to go into or have gone into with you
                                        To help our site that will be ready to serve you better.
                                        How we use cookies
                                        We use cookies and similar technologies for several purposes, depending on the context or service, including: 

                                        Recognizing you if you sign in to use our offerings. This allows us to provide you with recommendations, display personalized content, and provide other customized features and services.
                                        Keeping track of your specified preferences. This allows us to honor your likes and dislikes, such as your language and configuration preferences.
                                        Detecting and preventing fraudulent activity and improving security.
                                        Conducting research and diagnostics to improve our offerings.
                                        Reporting, which allows us to measure and analyze the performance of our offerings.
                                        First-party cookies

                                        Strictly Necessary cookies: These cookies are necessary for the website to function and cannot be switched off in our systems. They are often set in response to actions made by you, which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms.
                                        Functional cookies: These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages.
                                        Performance cookies: These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site.
                                        Targeting cookies: These cookies may be set through our site and may be used to build a profile of your interests and show you relevant adverts on other sites.
                                        Third-party cookies
                                        In addition to our own cookies, we may also use various third-party cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on. These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.
                                        What are your choices regarding cookies?
                                        Cookie Preferences on website: Our cookies allow you to take advantage of some essential and useful features. Blocking some types of cookies may impact your experience of our sites. You can change your cookie preferences at any time by clicking ‘Cookie Preferences’ in the footer of the 99YARDS  website.
                                        Browser settings: You can also manage browser cookies through your browser settings. The 'Help' feature on most browsers will tell you how to remove cookies from your device, prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie, how to disable cookies, and when cookies will expire. Check the support site for your browser to understand privacy settings available to you. If you block or reject some of our cookies through your browser’s settings, you might not be able to use certain offerings that require you to sign-in to an account and some features and services may not work. You might also have to manually adjust your preferences or settings every time you visit our website.

                                        Google Analytics Cookies
                                        We use Google Analytics to investigate the utilization of our site of users and visitors. Google Analytics assembles data about site use through cookies. The data assembled identifying with our site is utilized to make reports about the utilization of our site.
                                        Name of cookie
                                        Expiry period
                                        Purpose
                                        Company
                                        _ga
                                        Persistent - 2 years
                                        Analytics
                                        Google Analytics, Google LLC
                                        _ga_JMDY11H0YJ
                                        Persistent - 2 years
                                        Analytics
                                        Google Analytics, Google LLC
                                        _gat
                                        Persistent - 2 years
                                        Analytics
                                        Google Analytics, Google LLC
                                        _gid
                                        Persistent - 1 day
                                        Analytics
                                        Google Analytics, Google LLC
                                        _sp_root_domain_test_* (x 1535)
                                        Session cookie
                                        Analytics
                                        9pro.app
                                        gaVisitorTypeLong
                                        Persistent - 2 years
                                        Analytics
                                        Google Analytics, Google LLC
                                        gaVisitorTypeShort
                                        Persistent - 30 minutes
                                        Analytics
                                        Google Analytics, Google LLC
                                        Facebook Pixel
                                        _fpb,datr,dpr,fr,wd
                                        Collects anonymous statistics regarding usage of the 99YARDS.app website. These are third-party cookies. While 99YARDS.app 's use of Facebook causes these cookies to be used, 99YARDS.app itself does not control the data within the cookies themselves. The names of the cookies listed are provided as examples. 99YARDS.app does not directly control the names of the cookies involved, and the actual names may differ.
                                        These cookies enable us to:

                                        Determine the effectiveness of certain marketing campaigns
                                        Collect additional anonymous statistics (see below)
                                        You can learn more about Facebook's Tracking Pixel here
                                        California Consumer Rights

                                        The California Consumer Privacy Act provides specific rights to those who live in California. If you are a California-based consumer, as that term is defined under California law, this section shall apply in addition to all other applicable rights and information contained in this Statement.

                                        You have the right to request that we provide you with information about what personal information we collect, use, and disclose.
                                        You have the right to request that we delete personal information we, or our service providers, store about you. 
                                        We will not discriminate or retaliate against you if you elect to exercise any rights under this section of our Privacy Statement. 
                                        You may request that we not sell your personal information. As noted above, we do not sell your personal information and we only share your personal information with third parties, as described in this Statement.
                                        You have the right to designate an authorized agent to make a request on your behalf. Please see the Identity Verification Requirement below for information on our process for verifying that we have received a legally valid request.
                                        If you are a California consumer and have additional questions based on this section of our Privacy Statement or wish to submit a request to request that we not share your information with third parties, please contact us by email or through contact us page.
                                        See more at https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/

                                        According to caloppa, we agree to the following:


                                        Users can visit our site anonymously.
                                        Once this online privacy policy is established, we will put a link to it on our website, on the first significant web page after getting into our website.
                                        Our ONLINE PRIVACY POLICY link includes the term 'Privacy' and can certainly be on the page given above.
                                        You'll be notified of any ONLINE PRIVACY POLICY changes:
                                        Via Email
                                        Coppa (children online privacy protection action)
                                        With regards to the assortment of private information from children under age 13 years, the Children's Online Privacy Protection Act (COPPA) puts parents in charge. The Federal Trade Commission, United States' consumer safety firm, enforces the COPPA Guideline, which spells out what providers of websites and online services should do to safeguard children privatizes and security online. For more Details Click Here or below link
                                        https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule 
                                        GDPR-Customer data processing appendix:
                                        Customer Data" means any personal data that 99 YARDS processes on behalf of Customer via the Services, as more particularly described in this DPA.
                                        "Data Protection Laws" means all data protection laws and regulations applicable to a party’s processing of Customer Data under the Agreement, including, where applicable, EU Data Protection Law and Non-EU Data Protection Laws.
                                        GDPR-EU data protection law  
                                        “EU Data Protection Law” means all data protection laws and regulations applicable to Europe, including (i) Regulation 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (General Data Protection Regulation) (“GDPR“); (ii) Directive 2002/58/EC concerning the processing of personal data and the protection of privacy in the electronic communications sector; (iii) applicable national implementations of (i) and (ii); and (iv) in respect of the United Kingdom (“UK“) any applicable national legislation that replaces or converts in domestic law the GDPR or any other law relating to data and privacy as a consequence of the UK leaving the European Union.
                                        “Europe” means, for the purposes of this DPA, the European Union, the European Economic Area and/or their member states, Switzerland, and the United Kingdom.
                                        “Non-EU Data Protection Laws” means the California Consumer Privacy Act (“CCPA”); the Canadian Personal Information Protection and Electronic Documents Act (“PIPEDA”); and the Brazilian General Data Protection Law (“LGPD “), Federal Law no. 13,709/2018.
                                        “SCCs” means the standard contractual clauses for processors as approved by the European Commission or Swiss Federal Data Protection Authority (as applicable), which shall be applied only to transfers of Customer Data from the European Union.
                                        “Sensitive Data” means (a) social security number, passport number, driver’s license number, or similar identifier (or any portion thereof); (b) credit or debit card number (other than the truncated (last four digits) of a credit or debit card); (c) employment, financial, genetic, biometric or health information; (d) racial, ethnic, political or religious affiliation, trade union membership, or information about sexual life or sexual orientation; (e) account passwords; or (f) other information that falls within the definition of “special categories of data” under applicable Data Protection Laws.
                                        “Services Data” means any data relating to the Customer’s use, support and/or operation of the Services, including information relating to volumes, activity logs, frequencies, bounce rates or other information regarding emails and other communications Customer generates and sends using the Services.

                                        Parties' roles: If EU Data Protection Law or the LGPD applies to either party's processing of Customer Data, the parties acknowledge and agree that concerning the processing of Customer Data, Customer is the controller and is a processor acting on behalf of Customer, as further described in Annex A (Details of Data Processing) of this DPA.
                                        Purpose limitation: 99 YARDS shall process Customer Data only following Customer's documented lawful instructions as outlined in this DPA, as necessary to comply with applicable law, or as otherwise agreed in writing ("Permitted Purposes"). The parties agree that the agreement sets out the Customer's complete and final instructions to 99 YARDS concerning the processing of Customer Data, and processing outside the scope of these instructions (if any) shall require a prior written agreement between the parties.
                                        Prohibited data. Customer will not provide (or cause to be provided) any Sensitive Data to 99 YARDS for processing under the Agreement, and 99 YARDS will have no liability whatsoever for Sensitive Data, whether in connection with a Security Incident or otherwise. For the avoidance of doubt, this DPA will not apply to Sensitive Data.
                                        Customer compliance: Customer represents and warrants that (i) it has complied, and will continue to comply, with all applicable laws, including Data Protection Laws, in respect of its processing of Customer Data and any processing instructions it issues to 99 YARDS; and (ii) it has provided, and will continue to provide, all notice and has obtained, and will continue to obtain, all consents and rights necessary under Data Protection Laws for 99 YARDS   to process Customer Data for the purposes described in the agreement. Customer shall have sole responsibility for the accuracy, quality, and legality of Customer Data and how Customer acquired Customer data. Without prejudice to the generality of the foregoing, Customer agrees that it shall be responsible for complying with all laws (including Data Protection Laws) applicable to any emails or other content created, sent, or managed through the service, including those relating to obtaining consents (where required) to send emails, the content of the emails and its email deployment practices.
                                        The lawfulness of Customer's instructions: Customer will ensure that United Kingdom processing of the Customer Data by Customer's instructions will not cause 99 YARDS  to violate any applicable law, regulation, or rule, including, without limitation, Data Protection Laws. 99 YARDS shall promptly notify Customer in writing unless prohibited from doing so under EU Data Protection Laws if it becomes aware or believes that any data processing instruction from Customer violates the GDPR or any UK implementation of the GDPR.

                                        Your Legal Rights
                                        Under certain circumstances, you have rights under data protection laws in relation to your personal data.
                                        You may have the following rights: -


                                        Request access to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.
                                        Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.
                                        Request deletion of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.
                                        Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.
                                        Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:

                                        If you want us to establish the data's accuracy.
                                        Where our use of the data is unlawful, but you do not want us to erase it.
                                        Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.
                                        You have objected to our use of your data, but we need to verify whether we have overriding legitimate grounds to use it.
                                        Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.
                                        Withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain services to you.
                                        How do we protect your details?

                                        We have implemented industry-accepted administrative, physical, and technology-based security measures to protect against loss, misuse, unauthorized access, and alteration of personal information in our systems. We ensure that any employee, contractor, corporation, organization, or vendor who has access to personal information in our systems is subject to legal and professional obligations to safeguard that personal information.
                                        We do not use vulnerability scanning and/or scanning to PCI specifications.
                                        We use regular Malware Scanning.
                                        Your individual information is comprised behind secured systems and is merely accessible by a restricted number of folks who've special access privileges to such systems and must keep the information confidential carefully. Furthermore, all very sensitive/credit information you resource is encrypted via Secure Socket Layer (SSL) technology.
                                        We implement several security measures whenever a user gets into, submits, or accesses their information to keep up the protection of your individual information.
                                        While we strive to use commercially acceptable means to protect your personal information, no method of transmission over the Internet or form of electronic storage is 100 percent secure. Therefore, we cannot guarantee its absolute security.
                                        9 PRO prohibits unauthorized access or use of personal information stored on our servers. Such access is a violation of law, and we will fully investigate and press charges against any party that has illegally accessed information within our systems.
                                        Can-spam act
                                        The CAN-SPAM Act is a regulation that sets the guidelines for commercial email, establishes requirements for commercial announcements, offers recipients to have emails ceased from being delivered to them, and spells out hard fines for violations.
                                        We accumulate your email to be able to:

                                        Send information, react to questions, and/or other demands or questions
                                        To maintain compliance with CANSPAM, we consent to the next:
                                        Not use untrue or misleading subject matter or email addresses.
                                        Identify the concept as an advertisement in some realistic way.
                                        Include the physical address of our site headquarters or business
                                        Screen third-party email marketing services for conformity, if one can be used.
                                        Honor opt-out/unsubscribe demands quickly.
                                        Allow users to unsubscribe utilizing the link at the bottom of every email.
                                        If anytime you want to unsubscribe from receiving future email, you can email us at by using contact form at our website 9pro.app and we'll immediately remove you from ALL communication.
                                        Limitation of liability

                                        Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages so some of the above limitations may not apply to you.
                                        We make no legal representation that the website or services are appropriate or available for use in locations outside United States. You may access the website from outside United States.at your own risk and initiative and must bear all responsibility for compliance with any applicable foreign laws.
                                        Indemnification

                                        Upon visiting this website you agree release, indemnify, defend and hold harmless 9 PRO and any of its contractors, agents, employees, officers, directors, shareholders, affiliates and assigns from all liabilities, claims, damages, costs and expenses, including reasonable attorneys' fees and expenses, of third parties relating to or arising out of your use of the website content; your use of the services; your violation of any provision of these terms; any unauthorized information or data you supplied to us. You will have sole responsibility to defend us against any claim, but you must receive our prior written consent regarding any related settlement.
                                        Governing Law and Jurisdiction

                                        This website originates from United States. The laws of United States. without regard to its conflict of law principles will govern these terms to the contrary. You, hereby agree that all disputes arising out of or in connection with these terms shall be submitted to the exclusive jurisdiction of the United States. By using this website, you consent to the jurisdiction and venue of such courts in connection with any action, suit, proceeding, or claim arising under or by reason of these terms. You hereby waive any right to trial by jury arising out of these terms.
                                        Changes to this privacy notice
                                        We reserve the right to alter this privacy notice at any time. Such alterations will be posted on our website. You can also obtain an up-to-date copy of our privacy notice by contacting us.
                                        Contacting us
                                        If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may do so via the contact us or email us at moirai9app@gmail.com. 
                                        This document was last updated on January 8, 2022
                                    </Typography>
                                </Box>
                            </Modal>
                        </div>
                        <div>
                            <button className='modal-button' onClick={handleOpenModal3}>Code of Conduct</button>
                            <Modal
                                open={openModal3}
                                onClose={handleCloseModal3}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Code of Conduct
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        99 YARDS has an obligation to conduct its business in accordance with all applicable rules, regulations, and laws. We are committed to helping all Users act in a way that preserves trust and respect. This Code is meant as a guide to using our Site appropriately and must always be followed. Breaches of this Code are handled according to our Violations Policy will result in disciplinary action, up to, and including, account termination. Any questions regarding this Code should be addressed to the 99 YARDS Support Team, where we can provide you with additional information regarding the correct procedure(s) to follow and address any concerns you may have.
                                        Personal Behavior

                                        I will act ethically and with integrity.
                                        I will comply with all 99 YARDS s policies.
                                        I will respect the rights of all Users.
                                        I will not abuse confidential information or participate in any other illegal practice.
                                        I will have regard for Users' interests, rights, and safety.
                                        I will not harass, bully, or discriminate.
                                        I will not falsify my own or any other identity, and I will provide true and correct information.
                                        I will not seek to communicate or receive/initiate payments off-site.
                                        I will not agree to do work I am not capable of doing.
                                        I will not request the upfront release of Milestone Payments before I have delivered work.
                                        I will not abuse the services offered by my hired 99YARDS..
                                        User Content
                                        I am responsible for the content I post on 99 YARDS and:

                                        I will not post content that infringes upon any copyright or other intellectual property rights.
                                        I will not post content that violates any law or regulation.
                                        I will not post content that is defamatory.
                                        I will not post content that is obscene or contains child pornography.
                                        I will not post content that includes incomplete, false, or inaccurate information about any person, product, or service.
                                        I will not post content that contains any viruses or programming routines intended to damage any system.
                                        I will not post content that creates liability for 99YARDS or harms its business operations or reputation.
                                        Confidentiality

                                        I will respect confidentiality and privacy.
                                        I will not disclose information or documents I have acquired, other than as required by law or where authorization is given by 99 YARDS.
                                        Contact

                                        I will not ask other 99 Yards users for their private contact details and will communicate with them only through official website features.
                                        Fraud

                                        I will not engage in fraud.
                                        I will not create multiple accounts.
                                        I will not use the Site to illegally transfer funds.
                                        I will not use the Site to generate false feedback about any person, product, or service.
                                        Communication

                                        I will avoid exaggeration, derogatory remarks, and inappropriate references.
                                        I will not engage in personal attacks, negative or other unfair criticism, or any unprofessional conduct.
                                        Bidding

                                        I will not underbid to avoid fees.
                                        I will not participate in projects involving illegal behavior.
                                        I will only bid on projects that I plan to complete.
                                        Spam or Advertising

                                        I will not spam or advertise my website or service unless otherwise allowed.
                                        Affiliates

                                        I will not refer myself to the Affiliate Program.
                                        I will not obtain names from mailing lists, group emails, etc., to send unsolicited emails ("Spam").
                                        Payments

                                        I will not use 99 Yards to facilitate money exchange, including, but not limited to, cryptocurrency (e.g., bitcoin, Ethereum, etc.).
                                        I will comply with my obligations after the work is submitted by 99 Yards.
                                    </Typography>
                                </Box>
                            </Modal>
                        </div>
                        <div>
                            <button className="modal-button" onClick={handleOpenModal4}>Website Accessibility</button>
                            <Modal
                                open={openModal4}
                                onClose={handleCloseModal4}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Website Accessibility
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Everyone should have access to and enjoyment of the internet, and we're committed to making that a reality.
                                        99 Yards is committed to ensuring digital accessibility for people with disabilities. We are continually improving the user experience for everyone and applying the relevant accessibility standards.
                                        The Web Content Accessibility Guidelines (WCAG) define requirements for designers and developers to improve accessibility for people with disabilities. It defines three levels of conformance: Level A, Level AA, and Level AAA. 99 Yards is partially conformant with WCAG 2.1 level AA. Partially conformant means that some parts of the content do not fully conform to the accessibility standard.
                                        How we meet WCAG standards
                                        To make sure that our website is compliant with accessibility standards, our templates:

                                        Use valid HTML and CSS.
                                        Let users change aspects of the site's appearance to improve their own experience.
                                        Are easy to navigate no matter the input device or method.
                                        Have a logical content structure and a clear information hierarchy.
                                        Have layouts that adapt to different screen sizes without loss of functionality or usability.
                                        Provide sufficient color contrast between background and foreground items.
                                        Provide alternative text descriptions for non-text elements such as images.
                                        Most of our content meets WCAG 2.1 AA guidelines. We have, however, identified some instances where this is not the case.
                                        We welcome your feedback on the accessibility of 99 Yards. Please let us know if you encounter accessibility barriers on moirai9app@gmail.com.
                                        Contacting us
                                        If you would like to contact us to understand more about website accessibility, you may do so via the contact us or email us at moirai9app@gmail.com
                                        This document was last updated on January 8, 2022

                                    </Typography>
                                </Box>
                            </Modal> */}
                        </div>
                    </div>
                    <div className="follow-section">
                        <h1>Follow Us</h1>
                        <a href="https://www.instagram.com/99yardsapp/">
                            <img className='footer-icon' src={instaFooter} alt="Instagram"/>
                        </a>
                        <a href="https://twitter.com/99yardsapp">
                            <img className='footer-icon' src={xFooter} alt="X"/>
                        </a>
                        <a href="https://www.linkedin.com/company/99-yards">
                            <img className='footer-icon' src={linkedinFooter} alt="LinkedIn"/>
                        </a>
                        <a href="https://www.tiktok.com/@99yardsapp">
                            <img className='footer-icon' src={tiktokFooter} alt="TikTok"/>
                        </a>
                        <a href="https://www.pinterest.com/99YARDSapp/">
                            <img className='footer-icon' src={pintrestFooter} alt="Pinterest"/>
                        </a>
                        <a href="https://www.reddit.com/user/99YARDS/">
                            <img className='footer-icon' src={redditFooter} alt="Reddit"/>
                        </a>
                    </div>
                </div>
            {/* </div> */}
        </div>
    )
}
