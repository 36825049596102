export const PrivacyPolicy = () => {
    return(
        <div className="regulation-container">
            <div className="regulation-text-container">
                <h1>Privacy Policy</h1>
                <br/>
                <p>
                We know that in this digital age, your privacy is important. This Privacy Policy reflects our commitment to protect personal data and the choices we offer you regarding how your data is used. We welcome you to read more about how we keep your information safe, as well as how you can exercise your rights. In addition, our Privacy policy covers our treatment of data that may be personal to you.

‍We will review, update, and amend these policies from time to time consistent with our business needs and technology. We encourage you to check back periodically for new updates or changes. Your continued use of the service makes up your acceptance of any change to this Privacy Policy. We are the data controller of your information. We handle and process all data on behalf of our customers
You may likewise decide not to give us "discretionary" Personal Data; however please remember that without it, we will most likely be unable to provide you with the full scope of our administrations or with the best client experience when utilizing our Services.
This Privacy Policy (“Privacy Policy”) describes how 99 YARDS. will gather, use, and maintain your Personal Information on the 99YARDS.app. It will also explain your legal rights with respect to that information.
By using the website or services, you confirm that you have read and understand this Privacy Policy, and our Terms (together referred to herein as the “Agreement”). The Agreement governs the use of 99YARDS.app. 99 YARDS  will collect, use, and maintain information consistent with the Agreement.

We recommend that you read this Policy in full to ensure you are fully informed. However, if you only want to access a particular section of this Policy, then you can click on the relevant link below to jump to that section.

Privacy Policy 

What private data do we collect from the people who visit our website? 

Do Not Track 

How do we use your details? 

How we use cookies 

First-party cookies 

Third-party cookies

What are your choices regarding cookies? 

Google Analytics Cookies 

California Consumer Rights 

According to caloppa, we agree to the following: 

Coppa (children online privacy protection action) 
GDPR-Customer data processing appendix: 
GDPR-EU data protection law 

Your Legal Rights 

You may have the following rights: - 

How do we protect your details? 
Can-spam act 
We accumulate your email to be able to: 
Limitation of liability 
Indemnification 
Governing Law and Jurisdiction 
Changes to this privacy notice 
Contacting us 
What private data do we collect from the people who visit our website?
When you use the Service, you may provide us with information about you. This may include your name and contact information, financial information to make or receive payment for services obtained through the 99YARDS platform, or information to help us fill out tax forms. When you use the Service, we may also collect information related to your use of the Service and aggregate this with information about other users. 

Account Data: To use certain features (like to sell or buy services), you need to create a user account. 

Buyer account: When you create or update your account, we collect and store the data you provide, like your First name, Last name, email address, password, and assign you a unique identifying number (“Account Data”).
Artist Account: When you create an artist account, we collect and store the data you provide, like your First name, Last name, email address, password, Location (State/Country), and assign you a unique identifying number (“Account Data”).
Personal Data: Personal Data is information that can be used to identify you specifically, including your name, email address, telephone number, or demographic information like your hometown. You consent to give us this information by providing it to us voluntarily on our website or any mobile application. Your decision to disclose this data is entirely voluntary. You are under no obligation to provide this information, but your refusal may prevent you from accessing certain benefits from our website.
Profile Data: You can also choose to provide profile information like a photo, website link, social media profiles, or other data. Your Profile Data will be publicly viewable by others.
Financial Data: Financial data is related to your payment methods, such as credit card or bank transfer details. We collect financial data to allow you to purchase, order, or exchange services from our website. We store limited financial data. Most financial data is transferred to our payment processor, Stripe, and you should review these processors' Privacy Policy to determine how they use, disclose, and protect your financial data. 

Stripe Privacy Policy can be found here https://stripe.com/gb/privacy
Contact information. An Authorized User is required to provide some contact information (e.g., an email address) when making an account on the Services.
General Audience Service: The Service is general audience and intended for users 18 and older. We do not knowingly collect Personal Information from anyone younger than age 18. If we become aware that a child younger than 18 has provided us with Personal Information, we will use commercially reasonable efforts to delete such information from our files. If you are the parent or legal guardian of a child younger than age 18 and believe that 99 YARDS has collected Personal Information from your child, please contact us.

Do Not Track
Currently, various browsers — such as Internet Explorer, Firefox, and Safari — offer a “do not track” or “DNT” option that relies on a technology known as a DNT header, which sends a signal to Web sites’ visited by the user about the user's browser DNT preference setting. 99YARDS does not currently commit to responding to browsers' DNT signals with respect to the Company's Web sites, in part, because no common industry standard for DNT has been adopted by industry groups, technology companies or regulators, including no consistent standard of interpreting user intent. 99 YARDS  takes privacy and meaningful choice seriously and will make efforts to continue to monitor developments around DNT browser technology and the implementation of a standard.
How do we use your details?
We may utilize the data we procure from you when you enlist, make a buy, join our bulletin, respond to an examination or promoting correspondence, peruse the site, or utilize specific other site includes in the following ways:

Create your account; and
Deliver any services purchased by you to you; and
Correspond with you; and
Compile anonymous statistical data for our own use or for a third party's use; and
Assist law enforcement as necessary; and
Prevent fraudulent activity on our website or mobile app; and
Analyze trends to improve our website and offerings.
To fulfill or meet the reason you provided the information (e.g., to help provide our Site services to you).
To personalize and develop our site and the services we provide you and improve our offerings.
To provide certain features or functionality of the services on the site.
For marketing and promotions.
To create, maintain, customize, and secure your account with us.
To provide you with support, to communicate with you and respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses.
To personalize your experience and to deliver content and services relevant to your interests.
To help maintain the safety, security, and integrity of our site, services, databases, and other technology assets and business.
For testing, research, analysis, and website development, including to develop and improve our site and services.
To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.
To prevent illegal activity, fraud, and abuse.
Where we must play out the agreement we are going to go into or have gone into with you
To help our site that will be ready to serve you better.
How we use cookies
We use cookies and similar technologies for several purposes, depending on the context or service, including: 

Recognizing you if you sign in to use our offerings. This allows us to provide you with recommendations, display personalized content, and provide other customized features and services.
Keeping track of your specified preferences. This allows us to honor your likes and dislikes, such as your language and configuration preferences.
Detecting and preventing fraudulent activity and improving security.
Conducting research and diagnostics to improve our offerings.
Reporting, which allows us to measure and analyze the performance of our offerings.
First-party cookies

Strictly Necessary cookies: These cookies are necessary for the website to function and cannot be switched off in our systems. They are often set in response to actions made by you, which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms.
Functional cookies: These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages.
Performance cookies: These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site.
Targeting cookies: These cookies may be set through our site and may be used to build a profile of your interests and show you relevant adverts on other sites.
Third-party cookies
In addition to our own cookies, we may also use various third-party cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on. These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.
What are your choices regarding cookies?
Cookie Preferences on website: Our cookies allow you to take advantage of some essential and useful features. Blocking some types of cookies may impact your experience of our sites. You can change your cookie preferences at any time by clicking ‘Cookie Preferences’ in the footer of the 99YARDS  website.
Browser settings: You can also manage browser cookies through your browser settings. The 'Help' feature on most browsers will tell you how to remove cookies from your device, prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie, how to disable cookies, and when cookies will expire. Check the support site for your browser to understand privacy settings available to you. If you block or reject some of our cookies through your browser’s settings, you might not be able to use certain offerings that require you to sign-in to an account and some features and services may not work. You might also have to manually adjust your preferences or settings every time you visit our website.

Google Analytics Cookies
We use Google Analytics to investigate the utilization of our site of users and visitors. Google Analytics assembles data about site use through cookies. The data assembled identifying with our site is utilized to make reports about the utilization of our site.
Name of cookie
Expiry period
Purpose
Company
_ga
Persistent - 2 years
Analytics
Google Analytics, Google LLC
_ga_JMDY11H0YJ
Persistent - 2 years
Analytics
Google Analytics, Google LLC
_gat
Persistent - 2 years
Analytics
Google Analytics, Google LLC
_gid
Persistent - 1 day
Analytics
Google Analytics, Google LLC
_sp_root_domain_test_* (x 1535)
Session cookie
Analytics
9pro.app
gaVisitorTypeLong
Persistent - 2 years
Analytics
Google Analytics, Google LLC
gaVisitorTypeShort
Persistent - 30 minutes
Analytics
Google Analytics, Google LLC
Facebook Pixel
_fpb,datr,dpr,fr,wd
Collects anonymous statistics regarding usage of the 99YARDS.app website. These are third-party cookies. While 99YARDS.app 's use of Facebook causes these cookies to be used, 99YARDS.app itself does not control the data within the cookies themselves. The names of the cookies listed are provided as examples. 99YARDS.app does not directly control the names of the cookies involved, and the actual names may differ.
These cookies enable us to:

Determine the effectiveness of certain marketing campaigns
Collect additional anonymous statistics (see below)
You can learn more about Facebook's Tracking Pixel here
California Consumer Rights

The California Consumer Privacy Act provides specific rights to those who live in California. If you are a California-based consumer, as that term is defined under California law, this section shall apply in addition to all other applicable rights and information contained in this Statement.

You have the right to request that we provide you with information about what personal information we collect, use, and disclose.
You have the right to request that we delete personal information we, or our service providers, store about you. 
We will not discriminate or retaliate against you if you elect to exercise any rights under this section of our Privacy Statement. 
You may request that we not sell your personal information. As noted above, we do not sell your personal information and we only share your personal information with third parties, as described in this Statement.
You have the right to designate an authorized agent to make a request on your behalf. Please see the Identity Verification Requirement below for information on our process for verifying that we have received a legally valid request.
If you are a California consumer and have additional questions based on this section of our Privacy Statement or wish to submit a request to request that we not share your information with third parties, please contact us by email or through contact us page.
See more at https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/

According to caloppa, we agree to the following:


Users can visit our site anonymously.
Once this online privacy policy is established, we will put a link to it on our website, on the first significant web page after getting into our website.
Our ONLINE PRIVACY POLICY link includes the term 'Privacy' and can certainly be on the page given above.
You'll be notified of any ONLINE PRIVACY POLICY changes:
Via Email
Coppa (children online privacy protection action)
With regards to the assortment of private information from children under age 13 years, the Children's Online Privacy Protection Act (COPPA) puts parents in charge. The Federal Trade Commission, United States' consumer safety firm, enforces the COPPA Guideline, which spells out what providers of websites and online services should do to safeguard children privatizes and security online. For more Details Click Here or below link
https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule 
GDPR-Customer data processing appendix:
Customer Data" means any personal data that 99 YARDS processes on behalf of Customer via the Services, as more particularly described in this DPA.
"Data Protection Laws" means all data protection laws and regulations applicable to a party’s processing of Customer Data under the Agreement, including, where applicable, EU Data Protection Law and Non-EU Data Protection Laws.
GDPR-EU data protection law  
“EU Data Protection Law” means all data protection laws and regulations applicable to Europe, including (i) Regulation 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (General Data Protection Regulation) (“GDPR“); (ii) Directive 2002/58/EC concerning the processing of personal data and the protection of privacy in the electronic communications sector; (iii) applicable national implementations of (i) and (ii); and (iv) in respect of the United Kingdom (“UK“) any applicable national legislation that replaces or converts in domestic law the GDPR or any other law relating to data and privacy as a consequence of the UK leaving the European Union.
“Europe” means, for the purposes of this DPA, the European Union, the European Economic Area and/or their member states, Switzerland, and the United Kingdom.
“Non-EU Data Protection Laws” means the California Consumer Privacy Act (“CCPA”); the Canadian Personal Information Protection and Electronic Documents Act (“PIPEDA”); and the Brazilian General Data Protection Law (“LGPD “), Federal Law no. 13,709/2018.
“SCCs” means the standard contractual clauses for processors as approved by the European Commission or Swiss Federal Data Protection Authority (as applicable), which shall be applied only to transfers of Customer Data from the European Union.
“Sensitive Data” means (a) social security number, passport number, driver’s license number, or similar identifier (or any portion thereof); (b) credit or debit card number (other than the truncated (last four digits) of a credit or debit card); (c) employment, financial, genetic, biometric or health information; (d) racial, ethnic, political or religious affiliation, trade union membership, or information about sexual life or sexual orientation; (e) account passwords; or (f) other information that falls within the definition of “special categories of data” under applicable Data Protection Laws.
“Services Data” means any data relating to the Customer’s use, support and/or operation of the Services, including information relating to volumes, activity logs, frequencies, bounce rates or other information regarding emails and other communications Customer generates and sends using the Services.

Parties' roles: If EU Data Protection Law or the LGPD applies to either party's processing of Customer Data, the parties acknowledge and agree that concerning the processing of Customer Data, Customer is the controller and is a processor acting on behalf of Customer, as further described in Annex A (Details of Data Processing) of this DPA.
Purpose limitation: 99 YARDS shall process Customer Data only following Customer's documented lawful instructions as outlined in this DPA, as necessary to comply with applicable law, or as otherwise agreed in writing ("Permitted Purposes"). The parties agree that the agreement sets out the Customer's complete and final instructions to 99 YARDS concerning the processing of Customer Data, and processing outside the scope of these instructions (if any) shall require a prior written agreement between the parties.
Prohibited data. Customer will not provide (or cause to be provided) any Sensitive Data to 99 YARDS for processing under the Agreement, and 99 YARDS will have no liability whatsoever for Sensitive Data, whether in connection with a Security Incident or otherwise. For the avoidance of doubt, this DPA will not apply to Sensitive Data.
Customer compliance: Customer represents and warrants that (i) it has complied, and will continue to comply, with all applicable laws, including Data Protection Laws, in respect of its processing of Customer Data and any processing instructions it issues to 99 YARDS; and (ii) it has provided, and will continue to provide, all notice and has obtained, and will continue to obtain, all consents and rights necessary under Data Protection Laws for 99 YARDS   to process Customer Data for the purposes described in the agreement. Customer shall have sole responsibility for the accuracy, quality, and legality of Customer Data and how Customer acquired Customer data. Without prejudice to the generality of the foregoing, Customer agrees that it shall be responsible for complying with all laws (including Data Protection Laws) applicable to any emails or other content created, sent, or managed through the service, including those relating to obtaining consents (where required) to send emails, the content of the emails and its email deployment practices.
The lawfulness of Customer's instructions: Customer will ensure that United Kingdom processing of the Customer Data by Customer's instructions will not cause 99 YARDS  to violate any applicable law, regulation, or rule, including, without limitation, Data Protection Laws. 99 YARDS shall promptly notify Customer in writing unless prohibited from doing so under EU Data Protection Laws if it becomes aware or believes that any data processing instruction from Customer violates the GDPR or any UK implementation of the GDPR.

Your Legal Rights
Under certain circumstances, you have rights under data protection laws in relation to your personal data.
You may have the following rights: -


Request access to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.
Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.
Request deletion of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.
Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.
Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:

If you want us to establish the data's accuracy.
Where our use of the data is unlawful, but you do not want us to erase it.
Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.
You have objected to our use of your data, but we need to verify whether we have overriding legitimate grounds to use it.
Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.
Withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain services to you.
How do we protect your details?

We have implemented industry-accepted administrative, physical, and technology-based security measures to protect against loss, misuse, unauthorized access, and alteration of personal information in our systems. We ensure that any employee, contractor, corporation, organization, or vendor who has access to personal information in our systems is subject to legal and professional obligations to safeguard that personal information.
We do not use vulnerability scanning and/or scanning to PCI specifications.
We use regular Malware Scanning.
Your individual information is comprised behind secured systems and is merely accessible by a restricted number of folks who've special access privileges to such systems and must keep the information confidential carefully. Furthermore, all very sensitive/credit information you resource is encrypted via Secure Socket Layer (SSL) technology.
We implement several security measures whenever a user gets into, submits, or accesses their information to keep up the protection of your individual information.
While we strive to use commercially acceptable means to protect your personal information, no method of transmission over the Internet or form of electronic storage is 100 percent secure. Therefore, we cannot guarantee its absolute security.
9 PRO prohibits unauthorized access or use of personal information stored on our servers. Such access is a violation of law, and we will fully investigate and press charges against any party that has illegally accessed information within our systems.
Can-spam act
The CAN-SPAM Act is a regulation that sets the guidelines for commercial email, establishes requirements for commercial announcements, offers recipients to have emails ceased from being delivered to them, and spells out hard fines for violations.
We accumulate your email to be able to:

Send information, react to questions, and/or other demands or questions
To maintain compliance with CANSPAM, we consent to the next:
Not use untrue or misleading subject matter or email addresses.
Identify the concept as an advertisement in some realistic way.
Include the physical address of our site headquarters or business
Screen third-party email marketing services for conformity, if one can be used.
Honor opt-out/unsubscribe demands quickly.
Allow users to unsubscribe utilizing the link at the bottom of every email.
If anytime you want to unsubscribe from receiving future email, you can email us at by using contact form at our website 9pro.app and we'll immediately remove you from ALL communication.
Limitation of liability

Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages so some of the above limitations may not apply to you.
We make no legal representation that the website or services are appropriate or available for use in locations outside United States. You may access the website from outside United States.at your own risk and initiative and must bear all responsibility for compliance with any applicable foreign laws.
Indemnification

Upon visiting this website you agree release, indemnify, defend and hold harmless 9 PRO and any of its contractors, agents, employees, officers, directors, shareholders, affiliates and assigns from all liabilities, claims, damages, costs and expenses, including reasonable attorneys' fees and expenses, of third parties relating to or arising out of your use of the website content; your use of the services; your violation of any provision of these terms; any unauthorized information or data you supplied to us. You will have sole responsibility to defend us against any claim, but you must receive our prior written consent regarding any related settlement.
Governing Law and Jurisdiction

This website originates from United States. The laws of United States. without regard to its conflict of law principles will govern these terms to the contrary. You, hereby agree that all disputes arising out of or in connection with these terms shall be submitted to the exclusive jurisdiction of the United States. By using this website, you consent to the jurisdiction and venue of such courts in connection with any action, suit, proceeding, or claim arising under or by reason of these terms. You hereby waive any right to trial by jury arising out of these terms.
Changes to this privacy notice
We reserve the right to alter this privacy notice at any time. Such alterations will be posted on our website. You can also obtain an up-to-date copy of our privacy notice by contacting us.
Contacting us
If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may do so via the contact us or email us at moirai9app@gmail.com. 
This document was last updated on January 8, 2022
                
                </p>
            </div>

        </div>
    )
}