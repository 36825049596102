export const CodeOfConduct = () => {
    return(
        <div className="regulation-container">
            <div className="regulation-text-container">
                <h1>Code Of Conduct</h1>
                <br/>
                <p>
                99 YARDS has an obligation to conduct its business in accordance with all applicable rules, regulations, and laws. We are committed to helping all Users act in a way that preserves trust and respect. This Code is meant as a guide to using our Site appropriately and must always be followed. Breaches of this Code are handled according to our Violations Policy will result in disciplinary action, up to, and including, account termination. Any questions regarding this Code should be addressed to the 99 YARDS Support Team, where we can provide you with additional information regarding the correct procedure(s) to follow and address any concerns you may have.
                                        Personal Behavior

                                        I will act ethically and with integrity.
                                        I will comply with all 99 YARDS s policies.
                                        I will respect the rights of all Users.
                                        I will not abuse confidential information or participate in any other illegal practice.
                                        I will have regard for Users' interests, rights, and safety.
                                        I will not harass, bully, or discriminate.
                                        I will not falsify my own or any other identity, and I will provide true and correct information.
                                        I will not seek to communicate or receive/initiate payments off-site.
                                        I will not agree to do work I am not capable of doing.
                                        I will not request the upfront release of Milestone Payments before I have delivered work.
                                        I will not abuse the services offered by my hired 99YARDS..
                                        User Content
                                        I am responsible for the content I post on 99 YARDS and:

                                        I will not post content that infringes upon any copyright or other intellectual property rights.
                                        I will not post content that violates any law or regulation.
                                        I will not post content that is defamatory.
                                        I will not post content that is obscene or contains child pornography.
                                        I will not post content that includes incomplete, false, or inaccurate information about any person, product, or service.
                                        I will not post content that contains any viruses or programming routines intended to damage any system.
                                        I will not post content that creates liability for 99YARDS or harms its business operations or reputation.
                                        Confidentiality

                                        I will respect confidentiality and privacy.
                                        I will not disclose information or documents I have acquired, other than as required by law or where authorization is given by 99 YARDS.
                                        Contact

                                        I will not ask other 99 Yards users for their private contact details and will communicate with them only through official website features.
                                        Fraud

                                        I will not engage in fraud.
                                        I will not create multiple accounts.
                                        I will not use the Site to illegally transfer funds.
                                        I will not use the Site to generate false feedback about any person, product, or service.
                                        Communication

                                        I will avoid exaggeration, derogatory remarks, and inappropriate references.
                                        I will not engage in personal attacks, negative or other unfair criticism, or any unprofessional conduct.
                                        Bidding

                                        I will not underbid to avoid fees.
                                        I will not participate in projects involving illegal behavior.
                                        I will only bid on projects that I plan to complete.
                                        Spam or Advertising

                                        I will not spam or advertise my website or service unless otherwise allowed.
                                        Affiliates

                                        I will not refer myself to the Affiliate Program.
                                        I will not obtain names from mailing lists, group emails, etc., to send unsolicited emails ("Spam").
                                        Payments

                                        I will not use 99 Yards to facilitate money exchange, including, but not limited to, cryptocurrency (e.g., bitcoin, Ethereum, etc.).
                                        I will comply with my obligations after the work is submitted by 99 Yards.
                
                </p>
            </div>

        </div>
    )
}