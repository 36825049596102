// import { Link } from "react-router-dom";

export const Admin = () => {
    return (
        <div className="admin-container" >
            <button className="back-button" onClick={() => window.location.href = '/'}>Go Back</button>
            <div className="invite-section">
                <div className="pending-invites"onClick={() => { window.location.href = "/pendinginvites" }}>
                    <h1>Pending Invites</h1>
                    <h2>10</h2>
                </div>
                <div className="accepted-invites">
                    <h1>Accepted Invites</h1>
                    <h2>40</h2>
                </div>
                <div className="rejected-invites">
                    <h1>Rejected Invites</h1>
                    <h2>8</h2>
                </div>
            </div>
        </div>
    )
}