import { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/logo_primary.svg';
import accountIcon from '../assets/img/accounticon.svg';
import { Link } from "react-router-dom";
import instagramIcon from "../assets/img/instagram-icon.svg";
import linkedInIcon from "../assets/img/linkedin-icon.svg";
import redditIcon from "../assets/img/reddit-icon.svg";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Navbar expand="md" className="scrolled">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="Logo" style={{ width: '60px', height: '60px' }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="router-text">
            <a 
              href="/#home" 
              className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} 
              onClick={() => onUpdateActiveLink('home')}>
              Home
            </a>
            <a 
              href="/#aboutus" 
              className={activeLink === 'aboutus' ? 'active navbar-link' : 'navbar-link'} 
              onClick={() => onUpdateActiveLink('aboutus')}>
              About
            </a>
            <a 
              href="/#categories" 
              className={activeLink === 'categories' ? 'active navbar-link' : 'navbar-link'} 
              onClick={() => onUpdateActiveLink('categories')}>
              Categories
            </a>
            <a  
             href="/#download"  
             className={activeLink === 'getinvited' ? 'active navbar-link get-app-button' : 'navbar-link get-app-button'} 
             onClick={() => onUpdateActiveLink('getinvited')}>
             Get the App
              </a>

          </Nav>
          <Nav className="social-links ms-3">
            <a href="https://www.instagram.com/99yardsapp/" target="_blank" rel="noopener noreferrer">
              <img className="social-icon" src={instagramIcon} alt="Instagram" />
            </a>
            <a href="https://www.reddit.com/user/99YARDS/" target="_blank" rel="noopener noreferrer">
              <img className="social-icon" src={redditIcon} alt="Reddit" />
            </a>
            <a href="https://www.linkedin.com/company/99-yards" target="_blank" rel="noopener noreferrer">
              <img className="social-icon" src={linkedInIcon} alt="LinkedIn" />
            </a>
          </Nav>
          <Nav className="ms-auto">
            <span className="navbar-text">
              <div className="search">
                <div>
                  <input type="text" placeholder="Search" required />
                </div>
              </div>
              <div className="account-icon">
                {/* <Link to="/login"><img src={accountIcon} alt="account icon" /></Link> */}
                <img src={accountIcon} alt="account icon" />

              </div>
            </span>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}