import React, { useEffect, useState } from "react";
import { getAuth, applyActionCode } from "firebase/auth";

const VerifyEmail = () => {
  const auth = getAuth();
  const [status, setStatus] = useState("verifying"); // "verifying", "success", or "error"
  const [errorMessage, setErrorMessage] = useState("");

  const handleEmailVerification = async (oobCode) => {
    try {
      await applyActionCode(auth, oobCode);
      setStatus("success");
    } catch (error) {
      setErrorMessage(error.message || "Something went wrong. Please try again.");
      setStatus("error");
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const mode = queryParams.get("mode");
    const oobCode = queryParams.get("oobCode");

    if (mode === "verifyEmail" && oobCode) {
      handleEmailVerification(oobCode);
    } else {
      setStatus("error");
      setErrorMessage("Invalid verification link.");
    }
  }, []);

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      textAlign: "center",
      fontFamily: "Arial, sans-serif",
      padding: "20px",
    },
    card: {
      maxWidth: "400px",
      width: "100%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      padding: "20px",
      borderRadius: "8px",
    },
    spinner: {
      width: "50px",
      height: "50px",
      border: "5px solid #ccc",
      borderTop: "5px solid #4caf50",
      borderRadius: "50%",
      animation: "spin 1s linear infinite",
      margin: "20px auto",
    },
    successText: {
      color: "#4caf50",
    },
    errorText: {
      color: "#e74c3c",
    },
    button: {
      display: "inline-block",
      marginTop: "20px",
      padding: "10px 20px",
      backgroundColor: "#4caf50",
      color: "#fff",
      textDecoration: "none",
      borderRadius: "5px",
      fontSize: "16px",
    },
    errorButton: {
      backgroundColor: "#e74c3c",
    },
  };

  return (
    <div style={styles.container}>
      {status === "verifying" && (
        <div style={styles.card}>
          <div style={styles.spinner}></div>
          <h1>Verifying your email...</h1>
          <p>Please wait while we confirm your email address.</p>
        </div>
      )}
      {status === "success" && (
        <div style={styles.card}>
          <h1 style={styles.successText}>Email Verified Successfully!</h1>
          <p>Thank you for verifying your email address. You can now access all features.</p>
          {/* <a href="/" style={styles.button}>
            Go to Homepage
          </a> */}
        </div>
      )}
      {status === "error" && (
        <div style={styles.card}>
          <h1 style={styles.errorText}>Verification Failed</h1>
          <p>{errorMessage}</p>
          {/* <a href="/resend-verification" style={{ ...styles.button, ...styles.errorButton }}>
            Resend Verification Email
          </a> */}
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;
