import parsonSchool from '../assets/img/the-new-school-parsons.png';
import wolfandbadger from '../assets/img/wolfandbadgerlogo.png';
import flyingsolo from '../assets/img/flyingsolo.png';
import vntana from '../assets/img/vntana.png';
import threedlook from '../assets/img/3dlook.png';
import fit from '../assets/img/FITLogo.png';

export const Partnerships = () => {
    return (
        <div className='partnerships-title'>
        <h1>Our Ecosystem</h1>
            <div className="partnerships-background">
                <div className='partnerships-container'>
                    <div className='partnerships-carousel'>
                        <a href="https://www.newschool.edu/parsons/">
                            <img className="partnerships-image" src={parsonSchool} alt="Parson School" />
                        </a>
                        <a href="https://www.wolfandbadger.com/">
                            <img className="partnerships-image" src={wolfandbadger} alt="Wolf & Badger" />
                        </a>
                        <a href="https://flyingsolo.nyc/">
                            <img className="partnerships-image" src={flyingsolo} alt="Flying Solo" />
                        </a>
                        <a href="https://vntana.com/">
                            <img className="partnerships-image" src={vntana} alt="VNTANA" />
                        </a>
                        <a href="https://www.3dlook.me/">
                            <img className="partnerships-image" src={threedlook} alt="3DLOOK" />
                        </a>
                        <a href="https://www.fitnyc.edu/">
                            <img className="partnerships-image" src={fit} alt="FIT" />
                        </a>
                    </div>
                    <div className='partnerships-carousel'>
                        <a href="https://www.newschool.edu/parsons/">
                            <img className="partnerships-image" src={parsonSchool} alt="Parson School" />
                        </a>                        
                        <a href="https://www.wolfandbadger.com/">
                            <img className="partnerships-image" src={wolfandbadger} alt="Wolf & Badger" />
                        </a>
                        <a href="https://flyingsolo.nyc/">
                            <img className="partnerships-image" src={flyingsolo} alt="Flying Solo" />
                        </a>
                        <a href="https://vntana.com/">
                            <img className="partnerships-image" src={vntana} alt="VNTANA" />
                        </a>
                        <a href="https://www.3dlook.me/">
                            <img className="partnerships-image" src={threedlook} alt="3DLOOK" />
                        </a>
                        <a href="https://www.fitnyc.edu/">
                            <img className="partnerships-image" src={fit} alt="FIT" />
                        </a>
                    </div>    
                </div>
            </div>       
        </div>
    );
}