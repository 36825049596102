export const WebsiteAccessibility = () => {
    return(
        <div className="regulation-container">
            <div className="regulation-text-container">
                <h1>Website Accessibility</h1>
                <br/>
                <p>
                Everyone should have access to and enjoyment of the internet, and we're committed to making that a reality.
                                        99 Yards is committed to ensuring digital accessibility for people with disabilities. We are continually improving the user experience for everyone and applying the relevant accessibility standards.
                                        The Web Content Accessibility Guidelines (WCAG) define requirements for designers and developers to improve accessibility for people with disabilities. It defines three levels of conformance: Level A, Level AA, and Level AAA. 99 Yards is partially conformant with WCAG 2.1 level AA. Partially conformant means that some parts of the content do not fully conform to the accessibility standard.
                                        How we meet WCAG standards
                                        To make sure that our website is compliant with accessibility standards, our templates:

                                        Use valid HTML and CSS.
                                        Let users change aspects of the site's appearance to improve their own experience.
                                        Are easy to navigate no matter the input device or method.
                                        Have a logical content structure and a clear information hierarchy.
                                        Have layouts that adapt to different screen sizes without loss of functionality or usability.
                                        Provide sufficient color contrast between background and foreground items.
                                        Provide alternative text descriptions for non-text elements such as images.
                                        Most of our content meets WCAG 2.1 AA guidelines. We have, however, identified some instances where this is not the case.
                                        We welcome your feedback on the accessibility of 99 Yards. Please let us know if you encounter accessibility barriers on moirai9app@gmail.com.
                                        Contacting us
                                        If you would like to contact us to understand more about website accessibility, you may do so via the contact us or email us at moirai9app@gmail.com
                                        This document was last updated on January 8, 2022
                </p>
            </div>

        </div>
    )
}